import React from 'react';
import LeftSideMenu from './left-side-menu';
import SideMenuButton from './side-menu-button';

const LogViewerLeftSideMenu = ({showLeftSidePanel, 
                        onShowHideLeftSidePanel, 
                        showDepthPanel, 
                        onShowDepthPanel,
                        showXAxisPanel,
                        onShowXAxisPanel,
                        showPrintPanel,
                        onShowPrintPanel,
                        showTemplatesPanel,
                        onShowTemplatesPanel,
                        showViewsPanel,
                        onShowViewsPanel,
                        showExportPanel,
                        onShowExportPanel,
                        notSaved,
                        showInfoPanel,
                        onShowInfoPanel, 
                        templateSelected,
                        showView,
                        templateLoaded,
                        viewNotSaved
                     }: any) => {                            

    return (        
            <LeftSideMenu showLeftSidePanel={showLeftSidePanel}
                          onShowHideLeftSidePanel={onShowHideLeftSidePanel}>
                {onShowDepthPanel &&
                    <SideMenuButton highlighted={showDepthPanel} onClick={onShowDepthPanel} title="Set depth axis" icon="depth" disabled={!templateSelected} />
                }
                {onShowXAxisPanel &&
                    <SideMenuButton highlighted={showXAxisPanel} onClick={onShowXAxisPanel} title="Set X axis" icon="Atlas X Axis Icon" disabled={!templateSelected} />
                }
                {onShowPrintPanel &&
                    <SideMenuButton highlighted={showPrintPanel} onClick={onShowPrintPanel} title="Print" icon="Atlas Print Icon" disabled={!templateSelected} />
                }
                {onShowTemplatesPanel && (!showView) && <button className={`button is-tab button-icon mt-1 ${showTemplatesPanel ? "highlighted" : ""}`} onClick={onShowTemplatesPanel} title="Templates">
                    {notSaved && <span title="Template changed" className="badge"></span>}
                    <img src={notSaved ? "/images/icons/Atlas Save Template 2 Icon.svg" : "/images/icons/Atlas Save Template 2 Icon.svg"} />
                </button>}
                {onShowViewsPanel && (showView) && <button className={`button is-tab button-icon mt-1 ${showViewsPanel ? "highlighted" : ""}`} onClick={onShowViewsPanel} title="Views">
                    {viewNotSaved && <span title="View changed" className="badge"></span>}
                    <img src="/images/icons/view.svg" />
                </button>}
                {onShowInfoPanel && 
                    <SideMenuButton highlighted={showInfoPanel} onClick={onShowInfoPanel} title="Meta data" icon="Atlas Info Icon" disabled={!templateSelected} />
                }
                {onShowExportPanel && !showView && 
                    <SideMenuButton highlighted={showExportPanel} onClick={onShowExportPanel} title="Export" icon="Export Icon" disabled={!templateSelected} />                    
                }
            </LeftSideMenu>

    )
}

export default LogViewerLeftSideMenu;