
import '../image-filter.scss';
import '../panel.scss';
import GraphAdjustmentsTab from "./graph-adjustments-tabs";
import RightPanel from "../right-panel";
import Group from "./group";

const GraphAdjustments = ({ tracks, 
                            trackTypeCategories, 
                            selectedTrack, 
                            selectedGroupId,
                            setSelectedTrack, 
                            setTrackCollapsed, 
                            updateCurves, 
                            setShowGridlines, 
                            setScaleType, 
                            deleteTrack,
                            addCurve,
                            lithologyData,
                            clientId,
                            changeDataValue,
                            saveLithologyData,
                            saveTextData,
                            changeDepth,
                            changeTextData,                            
                            addDataValue,
                            addTextValue,                            
                            deleteDataValue,
                            selectedLithologyId,
                            showAdjustmentsPanel,
                            setSelectedLithologyId,
                            setTrackEditMode,
                            dataNotSaved,
                            showView,
                            groups,
                            boreholes } : any) => {  
  
  const adjustableTracks = tracks?.filter((track: any) => !track.noData || track.trackType === 5);
  const tracksOptions = adjustableTracks?.map((track: any) => { return { label: track.label, value: track.id } });

  const selectedTrackOption = tracksOptions?.find((track: any) => track.value === selectedTrack?.id);
  
  const onSetTrackCollapsed = (trackId: string, collapsed: boolean) => {
    setTrackCollapsed(trackId, collapsed);
  };

      
  return (
    <RightPanel title="Adjustments" icon="Atlas Adjustment Icon">
        <div>
              {showView && <div>
                {(groups || []).map((group: any, index: number) => { 
                  return (
                    <Group group={group} 
                      key={index}   
                      trackTypeCategories={trackTypeCategories}                 
                      setCollapsed={onSetTrackCollapsed}
                      selected={selectedGroupId == group.groupId}
                      updateCurves={updateCurves}
                      setSelectedTrack={setSelectedTrack}
                      selectedTrack={selectedTrack}
                      setTrackCollapsed={setTrackCollapsed}
                      setShowGridlines={setShowGridlines} 
                      setScaleType={setScaleType}
                      deleteTrack={deleteTrack}
                      addCurve={addCurve}     
                      lithologyData={lithologyData}
                      clientId={clientId}
                      changeDataValue={changeDataValue}
                      changeTextData={changeTextData}
                      saveLithologyData={saveLithologyData}
                      saveTextData={saveTextData}
                      changeDepth={changeDepth}                  
                      addDataValue={addDataValue}
                      addTextValue={addTextValue}
                      deleteDataValue={deleteDataValue}
                      selectedLithologyId={selectedLithologyId}
                      setSelectedLithologyId={setSelectedLithologyId}
                      showAdjustmentsPanel={showAdjustmentsPanel}
                      setTrackEditMode={setTrackEditMode} 
                      boreholes={boreholes}
                       />
                  )
                })}
                </div>}
              {!showView && <div>
                {(adjustableTracks || []).map((track: any, index: number) => {                  
                  const trackDataNotSaved = dataNotSaved.findIndex((t: any) => t ==  track.lithologyTypeId) >= 0;
                  return (
                    <GraphAdjustmentsTab key={track.id} 
                      track={track} 
                      tracks={tracks} 
                      trackTypeCategories={trackTypeCategories}
                      setSelectedTrack={setSelectedTrack}
                      setCollapsed={onSetTrackCollapsed}
                      isSelected={track.id === selectedTrack?.id}
                      updateCurves={updateCurves}
                      setShowGridlines={setShowGridlines} 
                      setScaleType={setScaleType}
                      deleteTrack={deleteTrack}
                      addCurve={addCurve}     
                      lithologyData={lithologyData}
                      clientId={clientId}
                      changeDataValue={changeDataValue}
                      changeTextData={changeTextData}
                      saveLithologyData={saveLithologyData}
                      saveTextData={saveTextData}
                      changeDepth={changeDepth}                  
                      addDataValue={addDataValue}
                      addTextValue={addTextValue}
                      deleteDataValue={deleteDataValue}
                      selectedLithologyId={selectedLithologyId}
                      setSelectedLithologyId={setSelectedLithologyId}
                      showAdjustmentsPanel={showAdjustmentsPanel}
                      setTrackEditMode={setTrackEditMode} 
                      dataNotSaved={trackDataNotSaved}                  
                    />
                )})}            
              </div>}
        </div>       
    </RightPanel>
  );
}

export default GraphAdjustments;
