import { useEffect } from 'react';
import Select from 'react-select';
import ColorPicker from '../Input/color-picker';

const CureProperties = (props: any) => {

const {         
        curveProperties,
        id,        
        setCurveProperties,
        visible,        
        setCurveTrackTypeId,
        showPointProperties,
        showLineProperties,
        showFillProperties,
        selectedTrackCategory,
        showStyles,
        showCureTypes
     } = props;

    useEffect(() => {      
      if (curveProperties.new && selectedTrackCategory?.id) {
        setCurveTrackTypeId(id, selectedTrackCategory.trackTypes[0].id);
      }
     }, [selectedTrackCategory?.id])

     if (!visible) {
       return null;
     }

     const onSetDialogPointStyle = (value: any) => {
      setCurveProperties(id, {...curveProperties, pointStyle: value.value});
     }

     const onSetDialogLineStyle = (value: any) => {
      setCurveProperties(id, {...curveProperties, lineStyle: value.value});
     }

     const onSetDialogCurveType = (value: any) => {
      setCurveProperties(id, {...curveProperties, curveType: value.value});
     }

     const onSetDialogPointSize = (value: any) => {
      setCurveProperties(id, {...curveProperties, pointSize: value.value});
     }

     const onSetDialogLineSize = (value: any) => {
       setCurveProperties(id, {...curveProperties, lineSize: value.value});
     }

     const setDialogShowPoints = (value: boolean) => {
        setCurveProperties(id, {...curveProperties, showPoints: value});
     };

    const onSetDialogShowLine = (value: boolean) => {
      setCurveProperties(id, {...curveProperties, showLine: value});
    };

    const setDialogShowFill = (value: boolean) => {
      setCurveProperties(id, {...curveProperties, showFill: value});
    };
    
     const onChangePointColor = (value: any) => {       
        setCurveProperties(id, {...curveProperties, pointColor: value  });
     };

     const onChangeLineColor = (value: any) => {
      setCurveProperties(id, {...curveProperties, lineColor: value });
    };

    const onChangeFillColor = (value: any) => {
      setCurveProperties(id, {...curveProperties, fillColor: value });
    };

     const pointStyleOptions = [
        { value: 'circle', label: <svg height={10} width={10}>
            <g transform={`translate(5, 5)`}>          
                <circle r={5} fill={curveProperties?.pointColor} stroke="none"/>
            </g>
        </svg> },
        { value: 'cross', label: <svg height={10} width={10}>
            <g>          
                <line x1="5" y1="0" x2="5" y2="10" stroke={curveProperties?.pointColor} strokeWidth="2" />
                <line x1="0" y1="5" x2="10" y2="5" stroke={curveProperties?.pointColor} strokeWidth="2" />
            </g>
        </svg> },
        { value: 'triangleUp', label: <svg height="10" width="10"><path d="M0,-6.204032394013997L5.372849659117709,3.1020161970069986L-5.372849659117709,3.1020161970069986Z" transform="translate(5,8)" fill={curveProperties?.pointColor}></path></svg> },
        { value: 'triangleDown', label: <svg height="10" width="10"><path d="M0,-6.204032394013997L5.372849659117709,3.1020161970069986L-5.372849659117709,3.1020161970069986Z" transform="translate(5,3) rotate(180)" fill={curveProperties?.pointColor}></path></svg> },
        { value: 'diamond', label: <svg height="15" width="10"><path d="M0,-6.580370064762462L3.7991784282579624,0L0,6.580370064762462L-3.7991784282579624,0Z" transform="translate(5,8)" fill={curveProperties?.pointColor}></path></svg> },
        { value: 'square', label: <svg height="10" width="10"><path d="M-4.47213595499958,-4.47213595499958h8.94427190999916v8.94427190999916h-8.94427190999916Z" transform="translate(5,5)" fill={curveProperties?.pointColor}></path></svg> }
        
      ]

      const selectedPointStyle = pointStyleOptions.find((o:any) => o.value === curveProperties?.pointStyle);

      const lineStyleOptions = [
        { value: 'line', label: <svg height={10} width={50}>
            <g>          
             <line x1="0" y1="5" x2="50" y2="5" stroke={curveProperties?.lineColor} strokeWidth="2" />
            </g>
        </svg> },
        { value: 'dots', label: <svg height={10} width={50}>
          <g>          
              <path strokeDasharray="1,1" d="M0 5 l50 0" stroke={curveProperties?.lineColor} strokeWidth="2" />
          </g>
        </svg> },
        { value: 'dash', label: <svg height={10} width={50}>
            <g>          
                <path strokeDasharray="5,5" d="M0 5 l50 0" stroke={curveProperties?.lineColor} strokeWidth="2" />
            </g>
        </svg> },
        { value: 'long-dash', label: <svg height={10} width={50}>
            <g>          
            <path strokeDasharray="10,5" d="M0 5 l50 0" stroke={curveProperties?.lineColor} strokeWidth="2" />
            </g>
        </svg> },
        { value: 'short-long-dash', label: <svg height={10} width={50}>
            <g>          
            <path strokeDasharray="3,3,6,3" d="M0 5 l50 0" stroke={curveProperties?.lineColor} strokeWidth="2" />
            </g>
        </svg> }
      ];

      const selectedLineStyle = lineStyleOptions.find((o:any) => o.value === curveProperties?.lineStyle);

      const curveTypeOptions: any = [
        // { value: 1, label: 'Curve' },
        // { value: 2, label: 'Linear' },
        // { value: 3, label: 'Step' }
      ];

      curveProperties?.allowedCurveTypes?.forEach((curveTypeId: any) => {
        let label;
          switch (curveTypeId) {
            case 1:
              label = "Curve"
              break;
            case 2:
              label = "Linear"
              break;
            case 3:
              label = "Step"
              break;
          }
          curveTypeOptions.push(  { value: curveTypeId, label });
      });
      
      const selectedCurveType = curveTypeOptions.find((o:any) => o.value === curveProperties?.curveType);

      let pointSizeOptions = [];

      for(var i = 1; i <= 15; i++) {
        pointSizeOptions.push({ value: i, label: i })
      }

      const selectedPointSize = pointSizeOptions.find((o:any) => o.value == curveProperties?.pointSize);

      const lineSizeOptions = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 }
      ]

      const selectedLineSize = lineSizeOptions.find((o:any) => o.value == curveProperties?.lineSize);

      const customStyles = {
        option: (provided: any, state: any) => ({
          ...provided,          
          backgroundColor: state.isSelected ? '#AAAAAA' : '#FFFFFF',
          padding: 5,
          paddingLeft: "12px"
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })        
      }

      const popover: any = {
        position: 'absolute',
        zIndex: '100',        
        right: '0px'
      }
      const cover: any = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }
      

return (
      <div>
         <table className="table">
           <thead>
             <tr>
               <td></td>
               <td className="has-text-centered">Show</td>
               <td className="has-text-centered">Hide</td>
               <td className="has-text-centered">Color</td>
             </tr>
           </thead>
           <tbody>
             {showPointProperties &&
             <tr>
               <td className="has-text-left">Points</td>
               <td className="has-text-centered"><input type="radio" name={`points${id}`} checked={curveProperties?.showPoints} onChange={() => setDialogShowPoints(true)}></input></td>
               <td className="has-text-centered"><input type="radio" name={`points${id}`} checked={!curveProperties?.showPoints} onChange={() => setDialogShowPoints(false)}></input></td>
               <td>
                  <ColorPicker className="color-picker-centered" color={curveProperties?.pointColor} onChange={onChangePointColor} />
               </td>                
             </tr>
            }
            {showLineProperties &&
             <tr>
               <td className="has-text-left">Line</td>
               <td className="has-text-centered"><input type="radio" name={`line${id}`} checked={curveProperties?.showLine} onChange={() => onSetDialogShowLine(true)}></input></td>
               <td className="has-text-centered"><input type="radio" name={`line${id}`} checked={!curveProperties?.showLine} onChange={() => onSetDialogShowLine(false)}></input></td>
               <td>
                  <ColorPicker className="color-picker-centered" color={curveProperties?.lineColor} onChange={onChangeLineColor} />
               </td>
             </tr>
            }
            {showFillProperties && 
             <tr>
               <td className="has-text-left">Fill</td>
               <td className="has-text-centered"><input type="radio" name={`fill${id}`} checked={curveProperties?.showFill} onChange={() => setDialogShowFill(true)} disabled={!curveProperties?.showLine}></input></td>
               <td className="has-text-centered"><input type="radio" name={`fill${id}`} checked={!curveProperties?.showFill} onChange={() => setDialogShowFill(false)} disabled={!curveProperties?.showLine}></input></td>
               <td>
                  <ColorPicker className="color-picker-centered" color={curveProperties?.fillColor} onChange={onChangeFillColor} />
               </td>               
             </tr>
             }        
           </tbody>
         </table>
         <table className="table">
           <thead>
             <tr>
               <td></td>
               {showStyles && <td className="has-text-centered">Style</td>}
               <td className="has-text-centered">Size</td>
             </tr>
           </thead>
           <tbody>
             {showPointProperties &&
             <tr>
               <td className="has-text-left">Points</td>               
                {showStyles && <td>
                    <Select options={pointStyleOptions} styles={customStyles}  classNamePrefix="select" value={selectedPointStyle} onChange={onSetDialogPointStyle} isDisabled={!curveProperties?.showPoints} />
                </td>}
                <td>
                    <Select options={pointSizeOptions} styles={customStyles}  classNamePrefix="select" value={selectedPointSize} onChange={onSetDialogPointSize}  isDisabled={!curveProperties?.showPoints}/>
                </td>
             </tr>
            }
            {showLineProperties &&
             <tr>
               <td className="has-text-left">Line</td>               
               {showStyles && <td><Select options={lineStyleOptions} styles={customStyles} classNamePrefix="select" value={selectedLineStyle} onChange={onSetDialogLineStyle} isDisabled={!curveProperties?.showLine}/></td>}
               <td>
                    <Select options={lineSizeOptions} styles={customStyles} classNamePrefix="select" value={selectedLineSize} onChange={onSetDialogLineSize} isDisabled={!curveProperties?.showLine} />
                </td>
             </tr>
            }
            {showCureTypes &&
              <tr>
                <td className="has-text-left">Type</td>               
                <td><Select options={curveTypeOptions} styles={customStyles} classNamePrefix="select" value={selectedCurveType} onChange={onSetDialogCurveType} isDisabled={!curveProperties?.showLine}/></td>
                <td></td>
              </tr>
            }
           </tbody>
         </table>         
    </div>
)}

export default CureProperties;