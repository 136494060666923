import DepthTrack from '../../components/log-viewer/depth-track';
import useWindowSize from '../../components/useWindowResize';
import './trackData.scss';
import getConfig from '../../app/config';
import auth from '../../components/auth';
import { useEffect, useRef, useState } from 'react';
import Tracks from './Tracks';

export default function TrackData(props : any) {

    const { 
      clientId, 
      projectId, 
      boreholeId, 
      startDepth, 
      endDepth, 
      templateId, 
      byBoreholeDepth,
      trackData,
      sortedTrackData,
      sortedGroupTrackData,
      template,
      showView,
      view,
      updateCurve,
      updateCurves,      
      setShowGridlines,
      setScaleType,
      setTrackWidth,
      setViewTrackWidth,
      depthUnit,
      removeTrack,      
      trackTypes,
      editMode,
      depthMajorIntervals,
      setDepthMajorIntervals,
      showDepthGridLines,
      setShowDepthGridlines,
      showDepthMinorIntervals,
      setShowDepthMinorIntervals,
      depthMinorIntervals,
      setDepthMinorIntervals,
      deletedCurves,
      selectedTrackCategory,
      setSelectedTrackCategory,
      trackHeaderClick,
      setMetaDataDepth,
      deselectAllTracks,
      selectLithology,
      addLithology,
      collapseGroup
     } = props;

    const windowSize: any = useWindowSize();
    const container: any = useRef();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);  

  function debounce(func: any, wait: any, immediate: any) {
    var timeout: any;
    return function (this: any) {
      var context : any = this,
        args: any = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  useEffect(() => {
    const handleWindowResize = debounce(function handleResize() {
      setWindowHeight(window.innerHeight);
    }, 250, null);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  if (!trackData) {
    return null;
  }
  
    const apiUrl = getConfig().apiUrl;

    const imageAuthToken = auth.getTokenForImages();
    
    const onUpdateCurve = (templateTrackId: string, curve: any) => {
      updateCurve(templateTrackId, curve);
    } 

    const onUpdateCurves = (templateTrackId: string, curves: any) => {
      updateCurves(templateId, templateTrackId, curves);
    }

    const onSetShowGridlines = (templateTrackId: string, trackTypeId: any, show: boolean) => {
      setShowGridlines(templateId, templateTrackId, trackTypeId, show);
    }

    const onSetScaleType = (templateTrackId: string, trackTypeId: any, scaleType: number) => {
      setScaleType(templateId, templateTrackId, trackTypeId, scaleType);
    }
    
    const onSetTrackWidth = (templateTrackId: string, width: number) => {
      setTrackWidth(templateId, templateTrackId, width);
    }

    const onSetViewTrackWidth = (groupId: any, trackId: string, width: number) => {
      setViewTrackWidth(groupId, trackId, width);
    }
    
    const onGroupTrackHeaderClick = (track: any, groupId: any) => {      
      trackHeaderClick(track, groupId)
    };

    const cumulativeHeaderHeight = 20.86;
    const headerHeight = 67;
    const headerBottomPadding = 13;
    const imageWithoutLegendHeaderHeight = 54;
    const imageWithLegendHeaderHeight = 65;
    
    let maxGraphCurves = sortedTrackData ? Math.max(...sortedTrackData.filter((track: any) => track.trackType !== 3 && track.trackType !== 4 && track.trackType !== 7 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0)) : 0;
    let maxCumulativeCurves = sortedTrackData ? Math.max(...sortedTrackData.filter((track: any) => track.trackType === 3 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0)) : 0;
    let maxLithologyCodes = sortedTrackData ? Math.max(...sortedTrackData.filter((track: any) => track.trackType === 5).map((track: any) => track?.codesAndColors.length ?? 0)) : 0;

    if (showView) {
      sortedGroupTrackData?.filter((group: any) => group.tracks).forEach((group: any) => {
        const groupMasGraphCurves = Math.max(...group.tracks.filter((track: any) => track.trackType !== 3 && track.trackType !== 4 && track.trackType !== 7 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0));
        if (groupMasGraphCurves > maxGraphCurves) {
          maxGraphCurves = groupMasGraphCurves;
        }

        const groupMaxCumulativeCurves = Math.max(...group.tracks.filter((track: any) => track.trackType === 3 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0));
        if (groupMaxCumulativeCurves > maxCumulativeCurves) {
          maxCumulativeCurves = groupMaxCumulativeCurves;
        }

        const groupMaxLithologyCodes = Math.max(...group.tracks.filter((track: any) => track.trackType === 5).map((track: any) => track?.codesAndColors.length ?? 0));
        if (groupMaxLithologyCodes > maxLithologyCodes) {
          maxLithologyCodes = groupMaxLithologyCodes;
        }
      });
    }
    
    const containsImageWithLegend = sortedTrackData?.some((track: any) => track.trackType === 1 && track.legend && !track.legendRollover);
        
    if (maxCumulativeCurves < 0) {
      maxCumulativeCurves = 0;
    }

    let minHeaderHeight = 0;
    if (containsImageWithLegend) {
      minHeaderHeight = imageWithLegendHeaderHeight;
    } else {
      minHeaderHeight = imageWithoutLegendHeaderHeight;
    }

    const curveHeights = (maxGraphCurves * headerHeight) - headerBottomPadding;
    if (curveHeights > minHeaderHeight) {
      minHeaderHeight = curveHeights;
    }

    const minCumulativeHeaderHeight = (maxCumulativeCurves * cumulativeHeaderHeight);

    const minLithologyHeaderHeight = (maxLithologyCodes * cumulativeHeaderHeight) + 19.2;
    
    if (minCumulativeHeaderHeight > minHeaderHeight) {
      minHeaderHeight = minCumulativeHeaderHeight;
    }

    if (minLithologyHeaderHeight > minHeaderHeight) {
      minHeaderHeight = minLithologyHeaderHeight;
    }
       
    const topMargin = template?.showValueAxisAnnotation ? 35 : 10;

    const trackTop = minHeaderHeight;// + topMargin;
    let imageHeaderHeight = 0;
    const imageCurveHeaderHeight = (minHeaderHeight + 123);
    if (containsImageWithLegend) {          
      imageHeaderHeight = imageWithLegendHeaderHeight + 125;
    } else {
      imageHeaderHeight = imageWithoutLegendHeaderHeight + 125;
    }

    if (imageCurveHeaderHeight > imageHeaderHeight) {
      imageHeaderHeight = imageCurveHeaderHeight;
    }
    
    const boreholeLabelHeight = 35;
    const imageHeight = windowSize.height - imageHeaderHeight - topMargin - (showView ? boreholeLabelHeight : 0);
    const availableHeight = windowHeight - 56 - 57 - (showView ? boreholeLabelHeight : 0);
    const depthTrackAvailableHeight = windowHeight - 56 - 57;
    const depthTrackTopMargin = topMargin + (showView ? boreholeLabelHeight : 0);

    return (        
        <>
           <div className="log-track-container">
            
            {true &&
            <>
            {startDepth != null && endDepth != null && <DepthTrack 
                startDepth={startDepth} 
                endDepth={endDepth} 
                depthUnit={depthUnit} 
                minHeaderHeight={minHeaderHeight}
                headerBottomPadding={headerBottomPadding}
                editMode={editMode}      
                availableHeight={depthTrackAvailableHeight}
                trackTop={trackTop}
                topMargin={depthTrackTopMargin}
                depthMajorIntervals={depthMajorIntervals} 
                setDialogMajorIntervals={setDepthMajorIntervals}
                showDepthGridLines={showDepthGridLines}
                setShowDepthGridlines={setShowDepthGridlines}
                depthMinorIntervals={depthMinorIntervals}
                setDepthMinorIntervals={setDepthMinorIntervals}
                showDepthMinorIntervals={showDepthMinorIntervals}
                setShowDepthMinorIntervals={setShowDepthMinorIntervals}
                deselectAllTracks={deselectAllTracks}
                showValueAxisAnnotation={template?.showValueAxisAnnotation} /> }

              <div ref={container} className="columns flex-container" style={{ overflowY: "hidden" }}>

              {showView && <>
                {sortedGroupTrackData?.map((group: any, index: number) => {  
                  let minWidth = 150;                  
                  const tracksWithData = group.tracks?.filter((track: any) => track.noData == false || track.noImage == false)
                  if (tracksWithData?.length > 0) {
                    minWidth = tracksWithData[0].trackWidth - 6;
                  }
                  return (<div key={group.groupId} className="column flex-item group-outline" style={{maxWidth: group.collapsed ? minWidth + "px" : "2000px" }}>
                    <div className='group-header-container' style={{backgroundColor: group.color, minHeight: boreholeLabelHeight }}>
                      <div className='group-viewer-header' style={{backgroundColor: group.color }}>{group.groupName}</div>
                      <div className='group-header-arrow-container'><button className='button group-header-button' style={{backgroundColor: group.color}} onClick={() => collapseGroup(group.groupId)}>{group.collapsed ? <img src="/images/icons/Atlas Close Icon Black.svg"></img> : <img src="/images/icons/Atlas Open Icon Black.svg"></img>}</button></div>
                    </div>
                    <div ref={container} className="columns flex-container" style={{ overflow: "hidden" }}>
                    <Tracks
                      clientId={clientId}
                      projectId={projectId}
                      boreholeId={boreholeId}
                      startDepth={startDepth}
                      endDepth={endDepth}
                      byBoreholeDepth={byBoreholeDepth}
                      sortedTrackData={group.tracks}
                      template={template}
                      depthUnit={depthUnit}
                      removeTrack={removeTrack}
                      trackTypes={trackTypes}
                      editMode={editMode}
                      depthMajorIntervals={depthMajorIntervals}
                      showDepthGridLines={showDepthGridLines}
                      showDepthMinorIntervals={showDepthMinorIntervals}
                      depthMinorIntervals={depthMinorIntervals}
                      deletedCurves={deletedCurves}
                      selectedTrackCategory={selectedTrackCategory}
                      setSelectedTrackCategory={setSelectedTrackCategory}
                      trackHeaderClick={(track: any) => onGroupTrackHeaderClick(track, group.groupId)}
                      setMetaDataDepth={setMetaDataDepth}
                      selectLithology={selectLithology}
                      addLithology={addLithology}
                      topMargin={topMargin}
                      availableHeight={availableHeight}
                      onUpdateCurve={onUpdateCurve}
                      onUpdateCurves={onUpdateCurves}
                      onSetShowGridlines={onSetShowGridlines}
                      onSetScaleType={onSetScaleType}                      
                      onSetTrackWidth={(trackId: any, width: number) => onSetViewTrackWidth(group.groupId, trackId, width)}
                      headerHeight={headerHeight}
                      minHeaderHeight={minHeaderHeight}
                      headerBottomPadding={headerBottomPadding}
                      windowSize={windowSize}
                      container={container}
                      imageHeight={imageHeight}
                      apiUrl={apiUrl}
                      imageAuthToken={imageAuthToken}                  
                      cumulativeHeaderHeight={cumulativeHeaderHeight}
                    />
                    </div>
                  </div>)
                })}
              </> }
              {!showView && <>
                  <Tracks
                    clientId={clientId}
                    projectId={projectId}
                    boreholeId={boreholeId}
                    startDepth={startDepth}
                    endDepth={endDepth}
                    byBoreholeDepth={byBoreholeDepth}
                    sortedTrackData={sortedTrackData}
                    template={template}
                    depthUnit={depthUnit}
                    removeTrack={removeTrack}
                    trackTypes={trackTypes}
                    editMode={editMode}
                    depthMajorIntervals={depthMajorIntervals}
                    showDepthGridLines={showDepthGridLines}
                    showDepthMinorIntervals={showDepthMinorIntervals}
                    depthMinorIntervals={depthMinorIntervals}
                    deletedCurves={deletedCurves}
                    selectedTrackCategory={selectedTrackCategory}
                    setSelectedTrackCategory={setSelectedTrackCategory}
                    trackHeaderClick	={trackHeaderClick}
                    setMetaDataDepth={setMetaDataDepth}
                    selectLithology={selectLithology}
                    addLithology={addLithology}
                    topMargin={topMargin}
                    availableHeight={availableHeight}
                    onUpdateCurve={onUpdateCurve}
                    onUpdateCurves={onUpdateCurves}
                    onSetShowGridlines={onSetShowGridlines}
                    onSetScaleType={onSetScaleType}
                    onSetTrackWidth={onSetTrackWidth}
                    headerHeight={headerHeight}
                    minHeaderHeight={minHeaderHeight}
                    headerBottomPadding={headerBottomPadding}
                    windowSize={windowSize}
                    container={container}
                    imageHeight={imageHeight}
                    apiUrl={apiUrl}
                    imageAuthToken={imageAuthToken}                  
                    cumulativeHeaderHeight={cumulativeHeaderHeight}
                    />              
              </> }
            </div>   
            </>}       
           </div>
        </>
    )
  }
  