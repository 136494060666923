import { useEffect, useState } from "react";
import SelectField from "../../Input/select-field";
const AddCurve = ({ trackTypeCategories, addCurve, boreholes, allowedTrackType }: any) => {

  const [selectedTrackCategory, setSelectedTrackCategory] = useState<any>(null);
  const [trackTypeId, setTrackTypeId] = useState<number>(17); //trackTypes[0]?.trackTypes[0]?.id
  const [boreholeId, setBoreholeId] = useState<number>(); 

  useEffect(() => {      
    setSelectedTrackCategory(trackTypeCategories[0]);
   }, [])

  useEffect(() => {      
    if (selectedTrackCategory?.id) {
        setTrackTypeId(+selectedTrackCategory.trackTypes[0].id);
    }
   }, [selectedTrackCategory?.id])

   useEffect(() => {      
    if (boreholes?.length) {
      setBoreholeId(+boreholes[0].id);        
    }
   }, [boreholes?.length])
  
  const onChangeTrackCategoryId = (e: any) => {    
    const trackTypeCategory = trackTypeCategories.find((trackCategory: any) => trackCategory.id == e.target.value);
    setSelectedTrackCategory(trackTypeCategory);
  }; 

  const onChangeTrackTypeId = (e: any) => {
    const value = e.target.value;
    setTrackTypeId(+value);    
  };

  const onAddCurve = () => {          
    const trackType = selectedTrackCategory.trackTypes.find((trackType: any) => trackType.id === trackTypeId);    
    addCurve(trackTypeId, trackType?.name, boreholeId);
  };

  const boreholeOptions = boreholes?.map((borehole: any) => { return {name: borehole.name, value: borehole.id }});

  const filteredTrackTypes = selectedTrackCategory?.trackTypes?.filter((trackType: any) => trackType.allowedTrackTypes.some((t: any) => t == allowedTrackType));
  

    return (
        <>
          {boreholes?.length && <SelectField label="Borehole" value={boreholeId} onChange={setBoreholeId} values={boreholeOptions} />}
          <div className="field">
            <label className="label">
              Curve Category
            </label>
            <div className="field-body">
              <div className="select">          
                <select value={selectedTrackCategory?.id} onChange={onChangeTrackCategoryId}>
                  {(trackTypeCategories || []).map((trackCategory: any, index: number) => 
                    <option key={index} value={trackCategory.id}>{trackCategory.name}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="label">
               Curve Type
            </div>
            <div className="field-body">
              <div className="select">          
                <select value={trackTypeId} onChange={onChangeTrackTypeId}>
                  {(filteredTrackTypes || []).map((trackType: any, index: number) => 
                    <option key={index} value={trackType.id}>{trackType.name}</option>
                  )}
                </select>
              </div>
            </div>
        </div>

        <button className="button is-light" onClick={onAddCurve}>Add</button>
      </>
    )
};

export default AddCurve;