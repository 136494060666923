import React, { useEffect, useState } from 'react';
import CureProperties from './curve-properties';
import './curve-dialog.scss';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const CurveComponent = (props: any) => {

const { 
        onCurveFormSubmit,
        dialogCurves,
        setDialogCurves,
        trackTypes,
        showPointProperties,
        showLineProperties,
        showFillProperties,
        selectedTrackCategory,
        setSelectedTrackCategory,
        canAdd,
        showStyles,
        showCureTypes
     } = props;

const [selectedCurveId, setSelectedCurveId] = useState<string>();

useEffect(() => {
  if (dialogCurves && dialogCurves.length > 0) {
    setSelectedCurveId(dialogCurves[0].id);
  }
}, 
[dialogCurves?.length]);

const onSetCurveProperties = (curveId: any, curveProperties: any) => {
  let curveIndex = dialogCurves.findIndex((curve: any) => curve.id === curveId);
  let curve = dialogCurves[curveIndex];
  let c = dialogCurves.slice();
  c.splice(curveIndex, 1);
  const curvesCopy = [...c, {...curve, ...curveProperties}];  
  curvesCopy.sort((a: any, b:any) => a.displayOrder - b.displayOrder);  
  setDialogCurves(curvesCopy);
};

const onSetCurveTrackTypeId = (curveId: any, newTrackTypeId: any) => {
  let curveIndex = dialogCurves.findIndex((curve: any) =>  curve.id === curveId);
  let curve = dialogCurves[curveIndex];
  let c = dialogCurves.slice();
  c.splice(curveIndex, 1);

  const allTrackTypes = trackTypes.reduce((previousValue : any, currentValue: any) => {
    previousValue.push(...currentValue.trackTypes);
    return previousValue;
  }, []);

  const trackType = allTrackTypes.find((trackType: any) => trackType.id === newTrackTypeId);  
  const curvesCopy = [...c, {...curve, trackTypeId: newTrackTypeId, displayName: trackType?.name }];
  curvesCopy.sort((a: any, b:any) => a.displayOrder - b.displayOrder);
  setDialogCurves(curvesCopy);
};

const onDeleteCurve = (curveId: any, displayName: string) => {
  if (dialogCurves.length > 1) {
    confirmAlert({
      title: 'Confirm Remove Curve',
      message: `Are you sure you want to remove curve ${displayName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteCurve(curveId);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }
};

const deleteCurve = (curveId: any) => {
  var curvesCopy = dialogCurves.slice();
  const index = curvesCopy.findIndex((curve: any) => curve.id === curveId);
  curvesCopy.splice(index, 1);
  setDialogCurves(curvesCopy);  
}

const hasMultipleCurves = dialogCurves.length > 1;

return (  
          <div>
            {(dialogCurves || []).map((curve: any, index: number) =>
            <div className="mb-4">
              <h4>{curve.displayName} {hasMultipleCurves && <span className="material-icons delete-icon" title="Delete" onClick={() => onDeleteCurve(curve.id, curve.displayName)}>delete</span>}</h4>
              
              <CureProperties
                key={curve.id}
                id={curve.id}
                curveProperties={curve}
                trackTypeId={curve.trackTypeId}
                setCurveProperties={onSetCurveProperties}
                setCurveTrackTypeId={onSetCurveTrackTypeId}
                visible={true}
                trackTypes={trackTypes} 
                showPointProperties={showPointProperties}
                showLineProperties={showLineProperties}
                showFillProperties={showFillProperties}
                selectedTrackCategory={selectedTrackCategory}
                setSelectedTrackCategory={setSelectedTrackCategory}
                showStyles={showStyles}
                showCureTypes={showCureTypes}
              />
            </div>
            )}
          </div>       
)}

export default CurveComponent;