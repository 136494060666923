import { createSlice, createAsyncThunk, current, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import getConfig from "../../app/config";
import fetchWithRefresh from "../../services/fetchWithRefresh";
import { getRandomColor } from "../../components/random";

export const getTemplates = createAsyncThunk<any, {templateType: number, clientId: string, projectId: string, boreholeId: string}>(
    "template/getTemplates",
    async (arg) => {
      const { clientId, projectId, templateType, boreholeId } = arg;                                                    
      return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/template/list/${templateType}`);
    }
)

export const getClientTemplates = createAsyncThunk<any, {templateType: number, clientId: string, projectId: string}>(
  "template/getClientTemplates",
  async (arg) => {
    const { clientId, projectId, templateType } = arg; 
    let url = `client/${clientId}/template/clientlist/${templateType}`;                                                   
    if (projectId) {
      url += `?projectId=${projectId}`;
    }

    return await fetchWithRefresh(getConfig().apiUrl + url);
  }
)

export const getTemplate = createAsyncThunk<string, string>(
  "template/getTemplate",
  async (id) => {
    return await fetchWithRefresh(getConfig().apiUrl + `template/${id}`);    
  }
)

export const getTrackData = createAsyncThunk<any, { clientId: string, projectId: string, boreholeId: string, pixelHeight: number, templateId: any, byBoreholeDepth: boolean, startDepth?: number | null, endDepth?: number | null, depthUnit?: number | null}>(
  "template/getTrackData",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, pixelHeight, templateId, byBoreholeDepth, startDepth, endDepth, depthUnit } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/trackData?pixelHeight=${pixelHeight}&templateId=${templateId}&byBoreholeDepth=${byBoreholeDepth}${startDepth ? "&startDepth=" + startDepth : ""}${endDepth ? "&endDepth=" + endDepth : ""}${depthUnit ? "&depthUnit=" + depthUnit : ""}`, thunkApi);
  }
)

// export const getMultipleBoreholeTrackData = createAsyncThunk<any, { clientId: string, projectId: string, boreholeIds: any[], pixelHeight: number, templateId: any, byBoreholeDepth: boolean, startDepth?: number | null, endDepth?: number | null, depthUnit?: number | null}>(
//   "template/getMultipleBoreholeTrackData",
//   async (arg, thunkApi) => {
//     const { clientId, projectId, boreholeIds, pixelHeight, templateId, byBoreholeDepth, startDepth, endDepth, depthUnit } = arg;    
//     const boreholeIdsQuery = boreholeIds.reduce((previous:string, boreholeId: any) => { return previous + `&boreholeIds=${boreholeId}`}, '');
//     return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/boreholes/trackData?pixelHeight=${pixelHeight}&templateId=${templateId}&byBoreholeDepth=${byBoreholeDepth}${startDepth ? "&startDepth=" + startDepth : ""}${endDepth ? "&endDepth=" + endDepth : ""}${depthUnit ? "&depthUnit=" + depthUnit : ""}${boreholeIdsQuery}`, thunkApi);
//   }
// )

export const getTrackDataForTrackTypes = createAsyncThunk<any, { clientId: string, projectId: string, boreholeId: string, pixelHeight: number, trackTypeIds: any, byBoreholeDepth: boolean, startDepth?: number | null, endDepth?: number | null, depthUnit?: number | null}>(
  "template/getTrackDataForTrackTypes",
  async (arg, thunkApi) => {  
    const { clientId, projectId, boreholeId, pixelHeight, trackTypeIds, byBoreholeDepth, startDepth, endDepth, depthUnit } = arg;    
    if (trackTypeIds.length === 0) {      
      return; 
    }    
    const queryTrackTypeIds = trackTypeIds.reduce((previous:string, trackTypeId: any) => { return previous + `&trackTypeIds=${trackTypeId}` }, '');
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/trackData?pixelHeight=${pixelHeight}${queryTrackTypeIds}&byBoreholeDepth=${byBoreholeDepth}${startDepth ? "&startDepth=" + startDepth : ""}${endDepth ? "&endDepth=" + endDepth : ""}${depthUnit ? "&depthUnit=" + depthUnit : ""}`, thunkApi);
  }
)

export const getMultipleBoreholeTrackDataForTrackTypes = createAsyncThunk<any, { clientId: string, boreholeTrackTypes: any[], pixelHeight: number, byBoreholeDepth: boolean, boreholeImageTypes: number[], startDepth?: number | null, endDepth?: number | null, depthUnit?: number | null}>(
  "template/getMultipleBoreholeTrackDataForTrackTypes",
  async (arg, thunkApi) => {  
    const { clientId, boreholeTrackTypes, boreholeImageTypes } = arg;    
    if (boreholeTrackTypes.length === 0 && boreholeImageTypes.length === 0) {      
      return; 
    }    
        
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/trackData`, thunkApi, 
      {
        method: 'POST',
        headers: {       
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(arg),  
      });
  }
)

export const getMultipleBoreholeTrackDataForTemplate = createAsyncThunk<any, { clientId: string, templateId: any, boreholeIds: any[], pixelHeight: number, byBoreholeDepth: boolean, startDepth?: number | null, endDepth?: number | null, depthUnit?: number | null}>(
  "template/getMultipleBoreholeTrackDataForTemplate",
  async (arg, thunkApi) => {      
    const { clientId } = arg;    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/trackData`, thunkApi, 
      {
        method: 'POST',
        headers: {       
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(arg),  
      });
  }
)

export const getMultipleBoreholeTrackDataForView = createAsyncThunk<any, { clientId: string, viewId: any, pixelHeight: number, byBoreholeDepth: boolean, startDepth?: number | null, endDepth?: number | null, depthUnit?: number | null}>(
  "template/getMultipleBoreholeTrackDataForView",
  async (arg, thunkApi) => {      
    const { clientId } = arg;    
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/trackData`, thunkApi, 
      {
        method: 'POST',
        headers: {       
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(arg),  
      });
  }
)

export const getTrackDataForImageTrackTypes = createAsyncThunk<any, { clientId: string, projectId: string, boreholeId: string, imageTypes: number[] }>(
  "template/getTrackDataForImageTrackTypes",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, imageTypes } = arg;
    const imageTypesQueryString = imageTypes.reduce((previousValue: any, currentValue: any) => { return previousValue + "imageTypes=" + currentValue + "&" }, "");
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/MetaData?${imageTypesQueryString}`, thunkApi);
  }
)

export const updateTemplate = createAsyncThunk<any, any>(
    "template/updateTemplate",
    async (template, thunkApi) => {
      return await fetchWithRefresh(getConfig().apiUrl + "template", thunkApi, {
        method: 'PUT',
        headers: {       
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(template),
      });
    }
)

export const deleteTemplate = createAsyncThunk<any, any>(
  "template/deleteTemplate",
  async (args, thunkApi) => {
    const {templateId} = args;
    return await fetchWithRefresh(getConfig().apiUrl + "template", thunkApi, {
      method: 'DELETE',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ id: templateId }),
    });
  }
)

export const addTemplate = createAsyncThunk<any, any>(
  "template/addTemplate",
  async (template, thunkApi) => {    
    return await fetchWithRefresh(getConfig().apiUrl + "template", thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(template),
    });
  }
)

export const changeTemplateLevel = createAsyncThunk<any, any>(
  "template/changeLevel",
  async (template, thunkApi) => {
    return await fetchWithRefresh(getConfig().apiUrl + `template/${template.id}/changeLevel`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(template),
    });
  }
)

export const saveLithologyData = createAsyncThunk<any, { clientId: string, projectId: string, boreholeId: number, data: any}>(
  "template/saveLithologyData",
  async (arg, thunkApi) => {    
    const { clientId, projectId, boreholeId, data } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/trackData`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
)

export const saveTextData = createAsyncThunk<any, { clientId: string, projectId: string, boreholeId: number, trackTypeId:any, data: any}>(
  "template/saveTextData",
  async (arg, thunkApi) => {
    const { clientId, projectId, boreholeId, trackTypeId, data } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `client/${clientId}/project/${projectId}/borehole/${boreholeId}/trackData/UpdateText`, thunkApi, {
      method: 'PUT',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({trackTypeId, data}),
    });
  }
)

const emptyTemplate = {
  cannotOverwrite: false,
  depthMajorIntervals: 6,
  depthMinorIntervals: 1,
  depthUnit: 1,
  errors: null,
  name: "",
  showDepthGridLines: true,
  showDepthMinorIntervals: false,
  showValueAxisAnnotation: true,
  tracks: [],
  statisticalTracks: []
}

export interface TemplateState {
    templatesLoading: boolean,
    templateLoading: boolean,
    trackDataLoading: boolean,
    templatesLoaded: boolean,
    allTemplates: any[],
    builtInTemplates: any[],
    userTemplates: any[],
    boreholeTemplates: any[],
    projectTemplates: any[],    
    clientTemplates: any[],
    selectedTemplateId: string | null,
    urlTemplateId: string | null,
    selectedBuiltInTemplateId: string | null,
    selectedUserTemplateId: string | null,
    selectedBoreholeTemplateId: string | null,
    selectedProjectTemplateId: string | null,
    selectedClientTemplateId: string | null,
    template: any,
    trackData: any[],
    groupTrackData: any[],
    trackImages: any[],
    groupTrackImages: any[],
    error: any,
    notSaved: boolean,
    canSaveTemplate: boolean,    
    canDeleteTemplate: boolean,    
    nextTemplateId: string | null,
    nextTemplateLevel: number | null,
    minDepth: number | null,
    maxDepth: number | null,
    deletedCurves: any[] | null,
    deepZoomMetaData: any,
    viewer: string | null,
    dataNotSaved: string[]
  }
  
  const initialState: TemplateState = {
    templatesLoading: false,
    templateLoading: false,
    trackDataLoading: false,
    templatesLoaded: false,
    allTemplates: [],
    builtInTemplates: [],
    userTemplates: [],
    boreholeTemplates: [],
    projectTemplates: [],
    clientTemplates: [],
    selectedTemplateId: null,
    urlTemplateId: null,
    selectedBuiltInTemplateId: null,
    selectedUserTemplateId: null,
    selectedBoreholeTemplateId: null,
    selectedProjectTemplateId: null,
    selectedClientTemplateId: null,
    template: emptyTemplate,
    trackData: [],
    groupTrackData: [],
    trackImages: [],
    groupTrackImages: [],
    error: null,
    notSaved: false,
    canSaveTemplate: false,
    canDeleteTemplate: false,
    nextTemplateId: null,
    nextTemplateLevel: null,
    minDepth: null,
    maxDepth: null,
    deletedCurves: null,
    deepZoomMetaData: {},
    viewer: null,
    dataNotSaved: []
  }

  const sortTemplates = (state: TemplateState) => {
    const defaultTemplates = state.builtInTemplates.filter((t:any) => t.cannotOverwrite);
    let userTemplates = state.builtInTemplates.filter((t:any) => !t.cannotOverwrite);
    userTemplates.sort((a: any, b: any) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    state.allTemplates.sort((a: any, b: any) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    state.builtInTemplates = [...defaultTemplates, ...userTemplates];
  }

const getTrackCurve = (state: any, trackId: string, curveId: string) => {
   const templateTrack = state.template?.tracks.find((t: any) => t.id == trackId);
   return templateTrack?.curves.find((c: any) => c.id == curveId);
};

const getTrack = (state: any, trackId: string) => {
  return state.template?.tracks.find((t: any) => t.id == trackId);  
};

const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {      
      updateCurve: (state, action: PayloadAction<any>) => {        
        const { templateTrackId, curve } = action.payload;        
        let trackCurve = getTrackCurve(state, templateTrackId, curve.id);

        if (trackCurve) {
          trackCurve.lineColor = curve.lineColor;
          trackCurve.fillColor = curve.fillColor;
          trackCurve.pointColor = curve.pointColor;
          trackCurve.lineStyle = curve.lineStyle;
          trackCurve.curveType = curve.curveType;
          trackCurve.fillStyle = curve.fillStyle;
          trackCurve.pointStyle = curve.pointStyle;
          trackCurve.lineSize = curve.lineSize;
          trackCurve.fillSize = curve.fillSize;
          trackCurve.pointSize = curve.pointSize;
          trackCurve.showLine = curve.showLine;
          trackCurve.showFill = curve.showFill;
          trackCurve.showPoints = curve.showPoints;

          trackCurve.xScaleMinimum = curve.xScaleMinimum;
          trackCurve.xScaleMaximum = curve.xScaleMaximum;
          trackCurve.xScaleMinimumExtent = curve.xScaleMinimumExtent;
          trackCurve.xScaleMaximumExtent = curve.xScaleMaximumExtent;
          trackCurve.manualScaleXMinimum = curve.manualScaleXMinimum;
          trackCurve.manualScaleXMaximum = curve.manualScaleXMaximum;
          trackCurve.majorIntervals = curve.majorIntervals;
          trackCurve.minorIntervals = curve.minorIntervals;
          trackCurve.manualMajorIntervals = curve.manualMajorIntervals;
          trackCurve.manualMinorIntervals = curve.manualMinorIntervals;
          trackCurve.logLessThaZero = curve.logLessThaZero;

          //state.notSaved = true;
        }        
      },
      addCurve: (state, action: PayloadAction<any>) => {        
        const { trackId } = action.payload;              
          
        const templateTrack = state.template?.tracks.find((t: any) => t.id == trackId);
        templateTrack?.curves.push({ new: true });
      },
      updateCurves: (state, action: PayloadAction<any>) => {
        
        const { templateTrackId, curves } = action.payload;    
        const templateTrack = state.template?.tracks.find((t: any) => t.id == templateTrackId);
        
        // find ones that no longer exist
        const curveIds = curves.map((curve: any) => curve.id);
        const deletedCurves = templateTrack.curves.filter((curve: any) => !curveIds.includes(curve.id));
        deletedCurves.forEach((curve: any) => {
          const curveIndex = templateTrack.curves.findIndex((index: any) => index.id === curve.id);
          templateTrack.curves.splice(curveIndex, 1);
        });

        state.deletedCurves = deletedCurves;

        curves.forEach((curve: any) => { 
          let trackCurve = getTrackCurve(state, templateTrackId, curve.id);
          if (trackCurve) {          
            trackCurve.displayOrder = curve.displayOrder;
            trackCurve.trackTypeId = curve.trackTypeId;
            trackCurve.lineColor = curve.lineColor;
            trackCurve.fillColor = curve.fillColor;
            trackCurve.pointColor = curve.pointColor;
            trackCurve.lineStyle = curve.lineStyle;
            trackCurve.curveType = curve.curveType;
            trackCurve.fillStyle = curve.fillStyle;
            trackCurve.pointStyle = curve.pointStyle;
            trackCurve.lineSize = curve.lineSize;
            trackCurve.fillSize = curve.fillSize;
            trackCurve.pointSize = curve.pointSize;
            trackCurve.showLine = curve.showLine;
            trackCurve.showFill = curve.showFill;
            trackCurve.showPoints = curve.showPoints;

            trackCurve.xScaleMinimum = curve.xScaleMinimum;
            trackCurve.xScaleMaximum = curve.xScaleMaximum;
            trackCurve.manualScaleXMinimum = curve.manualScaleXMinimum;
            trackCurve.manualScaleXMaximum = curve.manualScaleXMaximum;
            trackCurve.majorIntervals = curve.majorIntervals;
            trackCurve.minorIntervals = curve.minorIntervals;
            trackCurve.manualMajorIntervals = curve.manualMajorIntervals;
            trackCurve.manualMinorIntervals = curve.manualMinorIntervals;
            trackCurve.logLessThaZero = curve.logLessThaZero;

          } else {            
            curve.new = false;
            templateTrack.curves.push(curve);
          }
        });

        templateTrack.curves.sort((a: any, b:any) => a.displayOrder - b.displayOrder);
        state.notSaved = true;
      },
      setShowGridlines: (state, action: PayloadAction<any>) => {      

        const { templateTrackId, show } = action.payload;   
        let track = getTrack(state, templateTrackId);
        if (track) {
          track.showGridlines = show;
          state.notSaved = true;
        }        
      },      
      setScaleType: (state, action: PayloadAction<any>) => {        
        const { templateTrackId, scaleType } = action.payload;   
        let track = getTrack(state, templateTrackId);
        if (track) {
          track.scaleType = scaleType;
          state.notSaved = true;
        }        
      },
      setTrackImageFilter: (state, action: PayloadAction<any>) => {        
        const { templateTrackId, filter } = action.payload;   
        
        let track = getTrack(state, templateTrackId);
        if (track) {     
                  
          const changed = track.imageFilter != filter;
          track.imageFilter = filter;
          state.notSaved = changed;
        }        
      },
      setTrackWidth: (state, action: PayloadAction<any>) => {        
        const { templateTrackId, width } = action.payload;           
        const track = state.template?.tracks.find((t: any) => t.id == templateTrackId);        
        if (track) {
          track.trackWidth = Math.round(width);
          state.notSaved = true;
        }        
      },
      setSelectedBuiltInTemplateId: (state, action: PayloadAction<any>) => {             
        const selectedTemplateId = action.payload;        
        state.selectedTemplateId = selectedTemplateId;
        state.selectedBuiltInTemplateId = null;          
        state.selectedUserTemplateId = null;
        state.selectedBoreholeTemplateId = null;
        state.selectedProjectTemplateId = null;
        state.selectedClientTemplateId = null;
        const selectedTemplate = state.allTemplates?.find((t :any) => t.id === selectedTemplateId);        
        state.canSaveTemplate = false;
        state.canDeleteTemplate = false;
      },
      setSelectedUserTemplateId: (state, action: PayloadAction<any>) => {      
        const selectedTemplateId = action.payload;    
        state.selectedTemplateId = selectedTemplateId;
        state.selectedBuiltInTemplateId = null;      
        state.selectedUserTemplateId = null;  
        state.selectedBoreholeTemplateId = null;
        state.selectedProjectTemplateId = null;
        state.selectedClientTemplateId = null;
        const selectedTemplate = state.allTemplates?.find((t :any) => t.id === selectedTemplateId);
        console.log("selectedTemplate", current(selectedTemplate));        
        state.canSaveTemplate = !selectedTemplate?.cannotOverwrite;
        state.canDeleteTemplate = true;
      },
      setSelectedBoreholeTemplateId: (state, action: PayloadAction<any>) => {    
        const selectedTemplateId = action.payload;    
        state.selectedTemplateId = selectedTemplateId;  
        state.selectedBuiltInTemplateId = null;      
        state.selectedUserTemplateId = null;    
        state.selectedBoreholeTemplateId = null;  
        state.selectedProjectTemplateId = null;
        state.selectedClientTemplateId = null;
        const selectedTemplate = state.allTemplates?.find((t :any) => t.id === selectedTemplateId);
        state.canSaveTemplate = !selectedTemplate?.cannotOverwrite;
        state.canDeleteTemplate = false;
      },
      setSelectedProjectTemplateId: (state, action: PayloadAction<any>) => {    
        const selectedTemplateId = action.payload;    
        state.selectedTemplateId = selectedTemplateId;
        state.selectedBuiltInTemplateId = null;  
        state.selectedUserTemplateId = null;
        state.selectedBoreholeTemplateId = null;        
        state.selectedProjectTemplateId = null;  
        state.selectedClientTemplateId = null;
        const selectedTemplate = state.allTemplates?.find((t :any) => t.id === selectedTemplateId);
        state.canSaveTemplate = !selectedTemplate?.cannotOverwrite;
        state.canDeleteTemplate = false;
      },
      setSelectedClientTemplateId: (state, action: PayloadAction<any>) => {  
        const selectedTemplateId = action.payload; 
        state.selectedTemplateId = selectedTemplateId;
        state.selectedBuiltInTemplateId = null;
        state.selectedUserTemplateId = null;
        state.selectedBoreholeTemplateId = null;
        state.selectedProjectTemplateId = null;
        state.selectedClientTemplateId = null;  
        const selectedTemplate = state.allTemplates?.find((t :any) => t.id === selectedTemplateId);        
        state.canSaveTemplate = !selectedTemplate?.cannotOverwrite;
        state.canDeleteTemplate = false;
      },
      setSelectedTemplateId: (state, action: PayloadAction<any>) => {  
        const selectedTemplateId = action.payload; 
        state.selectedTemplateId = selectedTemplateId;         
        const selectedTemplate = state.allTemplates?.find((t :any) => t.id === selectedTemplateId);        
        state.canSaveTemplate = !selectedTemplate?.cannotOverwrite;
        state.canDeleteTemplate = false;
      },
      setNextTemplateId:(state, action: PayloadAction<any>) => {
        state.nextTemplateId = action.payload?.nextTemplateId;
        state.nextTemplateLevel = action.payload?.nextTemplateLevel;
      },
      clearNotSaved:(state) => {
        state.notSaved = false;
        state.dataNotSaved = [];
      },    
      addGraphTracks: (state, action: PayloadAction<any>) => {  
        action.payload.boreholeIds.forEach((boreholeId: any) => {
          action.payload.trackTypes.forEach((trackType: any) => {
            const color = getRandomColor();
            let myuuid = uuidv4();
            let curveId = uuidv4();
            state.template?.tracks.push({ 
              id: myuuid,                            
              trackType: 0,
              displayOrder: state.template?.tracks.length + 2, 
              new: true, 
              scaleType: 0,
              trackWidth: 150,
              showGridlines: true,
              showValueAxisAnnotation: state.template?.showValueAxisAnnotation,
              curves:[{
                id: curveId,
                boreholeId,
                displayName: trackType.displayName,
                displayOrder: 1,
                fillColor: color + "80",
                fillSize: 5,
                fillStyle: "",
                lineColor: color,
                lineSize: 1,
                lineStyle: "line",
                majorIntervals: 6,
                manualMajorIntervals: false,
                manualMinorIntervals: false,
                manualScaleXMaximum: false,
                manualScaleXMinimum: false,
                minorIntervals: 1,
                pointColor: color,
                pointSize: 5,
                pointStyle: "circle",            
                showFill: true,            
                showLine: true,
                showPoints: false,
                curveType: trackType.allowedCurveTypes[0],
                trackTypeId: trackType.trackTypeId,
                units: trackType.units,
                xScaleMaximum: null,
                xScaleMinimum: null
              }]
            });
          });
        });

        state.notSaved = true;
      },
      addHorizontalBarGraphTracks: (state, action: PayloadAction<any>) => {  
        action.payload.boreholeIds.forEach((boreholeId: any) => {          
          action.payload.trackTypes.forEach((trackType: any) => {            
            const color = getRandomColor();
            let myuuid = uuidv4();
            let curveId = uuidv4();
            state.template?.tracks.push({ 
              id: myuuid,                            
              trackType: 8,
              displayOrder: state.template?.tracks.length + 2, 
              new: true, 
              scaleType: 0,
              trackWidth: 150,
              showGridlines: true,
              showValueAxisAnnotation: state.template?.showValueAxisAnnotation,
              curves:[{
                id: curveId,
                boreholeId,
                displayName: trackType.displayName,
                displayOrder: 1,
                fillColor: color + "80",
                fillSize: 5,
                fillStyle: "",
                lineColor: color,
                lineSize: 1,
                lineStyle: "line",
                majorIntervals: 6,
                manualMajorIntervals: false,
                manualMinorIntervals: false,
                manualScaleXMaximum: false,
                manualScaleXMinimum: false,
                minorIntervals: 1,
                pointColor: color,
                pointSize: 5,
                pointStyle: "circle",            
                showFill: true,            
                showLine: true,
                showPoints: false,
                curveType: trackType.allowedCurveTypes[0],
                trackTypeId: trackType.trackTypeId,
                units: trackType.units,
                xScaleMaximum: null,
                xScaleMinimum: null
              }]
            });
          });
        });

        state.notSaved = true;
      },
      addImageTracks: (state, action: PayloadAction<any>) => {  
        action.payload.boreholeIds.forEach((boreholeId: any) => {
          action.payload.imageTrackTypes.forEach((imageTrackType: any) => {
            let myuuid = uuidv4();
            state.template?.tracks.push({ 
              id: myuuid,
              boreholeId,
              trackType: 1,
              displayOrder: state.template?.tracks.length + 2, 
              new: true,
              displayName: imageTrackType.displayName + " Image",
              imageType: imageTrackType.imageType,
              legend: imageTrackType?.legendName, 
              legendRollover: imageTrackType?.legendRollover,
              trackWidth: 150
            });
          });
        });

        state.notSaved = true;
      },
      addCumulativeTrack: (state, action: PayloadAction<any>) => {  

        const curves: any[] = [];
        let displayOrder = 1;
        action.payload.boreholeIds.forEach((boreholeId: any) => {
          action.payload.trackTypes.forEach((trackType: any) => {
            const color = getRandomColor();
            let curveId = uuidv4();
            curves.push({ 
              id: curveId,
              boreholeId,
              displayName: trackType.displayName,
              displayOrder: displayOrder++,
              fillColor: color + "80",
              fillSize: 5,
              fillStyle: "",
              lineColor: color,
              lineSize: 1,
              lineStyle: "line",
              majorIntervals: 6,
              manualMajorIntervals: false,
              manualMinorIntervals: false,
              manualScaleXMaximum: false,
              manualScaleXMinimum: false,
              minorIntervals: 1,
              pointColor: color,
              pointSize: 5,
              pointStyle: "circle",            
              showFill: true,            
              showLine: true,
              showPoints: false,
              trackTypeId: trackType.trackTypeId,
              units: "",
              xScaleMaximum: null,
              xScaleMinimum: null });
          });
        });

        let myuuid = uuidv4();
        state.template?.tracks.push({ 
          id: myuuid,
          trackType: 3,
          displayOrder: state.template?.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: state.template?.showValueAxisAnnotation,
          curves:curves});
        
        state.notSaved = true;
      },
      addTadpoleTrack: (state, action: PayloadAction<any>) => {  
        const curves: any[] = [];
        let displayOrder = 1;
        const color = getRandomColor();
        action.payload.trackTypes.forEach((trackType: any) => {          
          let curveId = uuidv4();
          curves.push({ 
            id: curveId,
            displayName: trackType.displayName,
            displayOrder: displayOrder++,
            fillColor: color + "80",
            fillSize: 5,
            fillStyle: "",
            lineColor: color,
            lineSize: 1,
            lineStyle: "line",
            majorIntervals: 6,
            manualMajorIntervals: false,
            manualMinorIntervals: false,
            manualScaleXMaximum: false,
            manualScaleXMinimum: false,
            minorIntervals: 1,
            pointColor: color,
            pointSize: 5,
            pointStyle: "circle",            
            showFill: false,
            showLine: true,
            showPoints: true,
            trackTypeId: trackType.trackTypeId,
            units: "",
            xScaleMaximum: null,
            xScaleMinimum: null});
        });

        let myuuid = uuidv4();
        state.template?.tracks.push({ 
          id: myuuid,
          trackType: 4,
          displayOrder: state.template?.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: state.template?.showValueAxisAnnotation,
          curves });
        
        state.notSaved = true;
      },
      addLithologyTrack: (state, action: PayloadAction<any>) => {          
        action.payload.trackTypes.forEach((trackType: any) => {
          let myuuid = uuidv4();
          state.template?.tracks.push({ 
            id: myuuid,
            trackType: 5,
            displayOrder: state.template?.tracks.length + 2, 
            new: true, 
            scaleType: 0,
            trackWidth: 100,
            showGridlines: true,
            showValueAxisAnnotation: state.template?.showValueAxisAnnotation,
            lithologyType: trackType.trackTypeId,
            displayName: trackType.displayName,
            curves:[{trackTypeId: trackType.trackTypeId}]});
        });
        
        state.notSaved = true;
      },
      addTextTrack: (state, action: PayloadAction<any>) => {                  
          let myuuid = uuidv4();
          const trackTypeId = action.payload.trackTypeId;
          state.template?.tracks.push({ 
            id: myuuid,
            trackType: 7,
            textTrackTypeId: trackTypeId,
            displayOrder: state.template?.tracks.length + 2, 
            new: true, 
            scaleType: 0,
            trackWidth: 300,            
            displayName: "Description"});
        
        state.notSaved = true;
      },
      addStatisticsTracks: (state, action: PayloadAction<any>) => {  
        action.payload.trackTypes.forEach((trackType: any) => {          
          if (!state.template?.statisticsTracks) {
            state.template.statisticsTracks = [];
          }

          state.template?.statisticsTracks.push(trackType);
        });

        state.notSaved = true;
      },
      deleteStatisticsTrack: (state, action: PayloadAction<any>) => {          
        const index = state.template.statisticsTracks.findIndex((t: any) => t.trackTypeId === action.payload.trackTypeId);
        if (index > -1) {
          state.template.statisticsTracks.splice(index,1);
        }        

        state.notSaved = true;
      },
      removeTrack: (state, action: PayloadAction<any>) => {  
        const { trackTypeId } = action.payload;        
        const index = state.template?.tracks.findIndex((track:any) => track.id === trackTypeId);
        if (index > -1) {
          state.template?.tracks.splice(index, 1);
          state.notSaved = true;
        }
      }, 
      updateTrackOrder: (state, action: PayloadAction<any>) => {  
        const { sourceIndex, destinationIndex } = action.payload;
        if (sourceIndex !== null && destinationIndex !== null) {                 
          const track = state.template?.tracks.splice(sourceIndex, 1)[0];          
          state.template?.tracks.splice(destinationIndex, 0, track);
          state.notSaved = true;
        }
      },      
      scrollTrackIntoView: (state, action: PayloadAction<any>) => {  
        const { trackId } = action.payload;   
        state.template?.tracks.forEach((t : any) => t.scrollIntoView = false);
        const track = state.template?.tracks.find((t: any) => t.id == trackId);
        if (track) {
          track.scrollIntoView = true;
        }
      },
      scrollAdjustmentIntoView: (state, action: PayloadAction<any>) => {  
        const { trackId } = action.payload;   
        state.template?.tracks.forEach((t : any) => t.scrollAdjustmentIntoView = false);
        const track = state.template?.tracks.find((t: any) => t.id == trackId);
        if (track) {
          track.scrollAdjustmentIntoView = true;
        }
      },
      setTrackCollapsed:  (state, action: PayloadAction<any>) => {  
        const { trackId, collapsed } = action.payload;                        
        const track = state.template?.tracks.find((t: any) => t.id == trackId);
        if (track) {
          track.collapsed = collapsed;
        }
      },
      setDepthUnit: (state, action: PayloadAction<any>) => {
        state.template.depthUnit = action.payload;
        state.notSaved = true;
      },
      setDepthMajorIntervals: (state, action: PayloadAction<any>) => {
        state.template.depthMajorIntervals = action.payload;
        state.notSaved = true;
      },
      setShowDepthGridlines: (state, action: PayloadAction<any>) => {
        state.template.showDepthGridLines = action.payload;
        state.notSaved = true;
      },
      setDepthMinorIntervals: (state, action: PayloadAction<any>) => {
        state.template.depthMinorIntervals = action.payload;
        state.notSaved = true;
      },
      setShowDepthMinorIntervals: (state, action: PayloadAction<any>) => {
        state.template.showDepthMinorIntervals = action.payload;
        state.notSaved = true;
      },
      setShowValueAxisAnnotation: (state, action: PayloadAction<any>) => {
        const showValueAxisAnnotation = action.payload;
        state.template.showValueAxisAnnotation = showValueAxisAnnotation;
        state.template?.tracks.forEach((track: any) => {track.showValueAxisAnnotation = showValueAxisAnnotation});
        state.notSaved = true;
      },
      clearTemplatesLoaded: (state) => {
        state.template = {...emptyTemplate};
        state.allTemplates = [];
        state.templatesLoaded = false;
        state.selectedTemplateId = null;
      },
      clearTemplateLoading: (state) => {
        state.templateLoading = true;
      },
      clearTemplate: (state) => {
        state.template = {...emptyTemplate};
        state.selectedTemplateId = null;
      },
      setViewer: (state, action: PayloadAction<any>) => {
        state.viewer = action.payload;
      },      
      changeDepth: (state, action: PayloadAction<any>) => {
        const { trackTypeId, id, newDepth } = action.payload;
        const trackData = state.trackData.find((t: any) => t.trackTypeId === trackTypeId)?.data;
        if (!trackData) {
          return;
        }
        
        const data = trackData.find((d: any) => d.id === id);
        if (+newDepth !== data.depth) {
          data.depth = +newDepth;
          trackData.sort((a: any, b: any) => a.depth - b.depth);
          if (state.dataNotSaved.findIndex((t: any) => t == trackTypeId) === -1) {
            state.dataNotSaved.push(trackTypeId);
          }
        }
      },
      addDataValue: (state, action: PayloadAction<any>) => {
        const { id, trackTypeId, depth, value, color, lithologyLexiconId, showView, boreholeId } = action.payload;
        
        if (showView) {
          let track = state.groupTrackData.find((t: any) => t.trackTypeId === trackTypeId && t.boreholeId === boreholeId);
          if (!track) {
            track = { trackTypeId, data: [] };
            state.groupTrackData.push(track);
          }
  
          if (!track.data) {
            track.data = [];
          }
  
          const trackData = track.data;        
          
          const data = { id: id ?? uuidv4(), depth, value, color, lithologyLexiconId };
          trackData.push(data);
          trackData.sort((a: any, b: any) => a.depth - b.depth);
          if (state.dataNotSaved.findIndex((t: any) => t == trackTypeId) === -1) {
            state.dataNotSaved.push(trackTypeId);
          }
        } else {
          let track = state.trackData.find((t: any) => t.trackTypeId === trackTypeId);
          if (!track) {
            track = { trackTypeId, data: [] };
            state.trackData.push(track);
          }
  
          if (!track.data) {
            track.data = [];
          }
  
          const trackData = track.data;        
          
          const data = { id: id ?? uuidv4(), depth, value, color, lithologyLexiconId };
          trackData.push(data);
          trackData.sort((a: any, b: any) => a.depth - b.depth);
          if (state.dataNotSaved.findIndex((t: any) => t == trackTypeId) === -1) {
            state.dataNotSaved.push(trackTypeId);
          }
        }        
      },
      changeDataValue: (state, action: PayloadAction<any>) => {
        const { id, trackTypeId, value, color, lithologyLexiconId, showView, boreholeId } = action.payload;
        const trackData = showView ? state.groupTrackData.find((t: any) => t.trackTypeId === trackTypeId && t.boreholeId === boreholeId)?.data : state.trackData.find((t: any) => t.trackTypeId === trackTypeId)?.data;
        const data = trackData.find((d: any) => d.id === id);
        data.lithologyLexiconId = lithologyLexiconId;
        data.value = value;
        data.color = color;
        if (state.dataNotSaved.findIndex((t: any) => t == trackTypeId) === -1) {
          state.dataNotSaved.push(trackTypeId);
        }
      },
      // this is also used for deleting text data
      deleteDataValue: (state, action: PayloadAction<any>) => {
        const { trackTypeId, id, showView, boreholeId } = action.payload;
        const trackData = showView ? state.groupTrackData.find((t: any) => t.trackTypeId === trackTypeId && t.boreholeId === boreholeId)?.data : state.trackData.find((t: any) => t.trackTypeId === trackTypeId)?.data;
        const dataIndex = trackData.findIndex((d: any) => d.id === id);
        trackData.splice(dataIndex, 1);
        if (state.dataNotSaved.findIndex((t: any) => t == trackTypeId) === -1) {
          state.dataNotSaved.push(trackTypeId);
        }
      }, 
      addTextValue: (state, action: PayloadAction<any>) => {        
        const { id, textTrackTypeId, fromDepth, toDepth, value, showView, boreholeId } = action.payload;
        let track = showView ? state.groupTrackData.find((t: any) => t.trackTypeId === textTrackTypeId && t.boreholeId === boreholeId) : state.trackData.find((t: any) => t.trackTypeId === textTrackTypeId);
        if (!track) {
          track = { trackTypeId: textTrackTypeId, data: [] };
          state.trackData.push(track);
        }

        if (!track.data) {
          track.data = [];
        }

        const trackData = track.data;        
        
        const data = { id: id ?? uuidv4(), fromDepth, toDepth, value };
        trackData.push(data);
        trackData.sort((a: any, b: any) => a.fromDepth - b.fromDepth);
        if (state.dataNotSaved.findIndex((t: any) => t == textTrackTypeId) === -1) {
          state.dataNotSaved.push(textTrackTypeId);
        }
      },
      changeTextData: (state, action: PayloadAction<any>) => {        
        const { trackTypeId, data, showView, boreholeId } = action.payload;
        let track = showView ? state.groupTrackData.find((t: any) => t.trackTypeId === trackTypeId && t.boreholeId === boreholeId) : state.trackData.find((t: any) => t.trackTypeId === trackTypeId);
        track.data = data;        
          // if (state.dataNotSaved.findIndex((t: any) => t == trackTypeId) === -1) {
          //   state.dataNotSaved.push(trackTypeId);
          // }
        
      },                 
      setTrackEditMode: (state, action: PayloadAction<any>) => {
        const { trackId, editMode } = action.payload;     
        const track = state.template?.tracks.find((t: any) => t.id == trackId);
        track.trackEditMode = editMode;        
      }, 
      addNewTemplate: (state) => {       
        state.selectedBuiltInTemplateId = null;
        state.selectedUserTemplateId = null;
        state.selectedBoreholeTemplateId = null;              
        state.selectedProjectTemplateId = null;
        state.selectedClientTemplateId = null;
        state.selectedTemplateId = null;
        state.canSaveTemplate = false;
        state.template = {...emptyTemplate};
      },
      clearTrackData: (state) => {
        state.trackData = [];
      },
      clearUrlTemplateId: (state) => {
        state.urlTemplateId = null;
      }
    },
    extraReducers:  (builder) => {
        builder.addCase(getTemplates.pending, (state, { payload }) => {
            state.templatesLoading = true;
            state.templatesLoaded = false;
            state.error = null;
          });
        builder.addCase(getTemplates.fulfilled, (state, action) => {          
            const { payload } = action;
            state.templatesLoading = false;
            if (!payload.success) {
              state.error = payload.errors;
            } else {              
              state.allTemplates = payload.allTemplates;
              state.builtInTemplates = payload.builtInTemplates;
              state.userTemplates = payload.userTemplates;
              state.boreholeTemplates = payload.boreholeTemplates;
              state.projectTemplates = payload.projectTemplates;
              state.clientTemplates = payload.clientTemplates;
              state.templatesLoaded = true;
              sortTemplates(state);
            }
          });
        builder.addCase(getTemplates.rejected, (state, action) => {         
            state.templatesLoading = false;
            state.error = action.error;
          });

        builder.addCase(getClientTemplates.pending, (state, { payload }) => {
            state.templatesLoading = true;
            state.templatesLoaded = false;
            state.error = null;
          });
        builder.addCase(getClientTemplates.fulfilled, (state, action) => {          
            const { payload } = action;
            state.templatesLoading = false;
            if (!payload.success) {
              state.error = payload.errors;
            } else {              
              state.builtInTemplates = payload.builtInTemplates;
              state.userTemplates = payload.userTemplates;
              state.projectTemplates = payload.projectTemplates;
              state.clientTemplates = payload.clientTemplates;
              state.templatesLoaded = true;
              sortTemplates(state);
            }
          });
        builder.addCase(getClientTemplates.rejected, (state, action) => {         
            state.templatesLoading = false;
            state.error = action.error;
          });

        builder.addCase(getTemplate.pending, (state, { payload }) => {
            state.templateLoading = true;
          });

        builder.addCase(getTemplate.rejected, (state, { payload }) => {
            state.templateLoading = false;
          });

        builder.addCase(getTemplate.fulfilled, (state, { payload }:any) => {
          state.templateLoading = false;
          if (!payload.success) {
            state.error = payload.errors;
          } else {
            
            const template: any = payload;
            template.tracks.forEach((track: any) => {
              track.collapsed = true;
            });
            
            state.template = template;
          }
          });

        builder.addCase(updateTemplate.fulfilled, (state, { payload }) => {   
            if (payload?.success || payload.status == 200) {
              if (state.nextTemplateId) {              
                  // state.selectedBuiltInTemplateId = null;
                  // state.selectedUserTemplateId = null;
                  // state.selectedBoreholeTemplateId = null;
                  // state.selectedProjectTemplateId = null;
                  // state.selectedClientTemplateId = null;
                switch (state.nextTemplateLevel) {
                  case 0: 
                    state.selectedBuiltInTemplateId = state.nextTemplateId;
                    break;
                  case 1: 
                    state.selectedUserTemplateId = state.nextTemplateId;
                    break;
                  case 2: 
                    state.selectedBoreholeTemplateId = state.nextTemplateId;
                    break;
                  case 3: 
                    state.selectedProjectTemplateId = state.nextTemplateId;
                    break;
                  case 4: 
                    state.selectedClientTemplateId = state.nextTemplateId;
                    break;                  
                }
                
                state.nextTemplateId = null;
              } else {
                state.selectedBuiltInTemplateId = null;
                state.selectedUserTemplateId = null;
                state.selectedBoreholeTemplateId = null;              
                state.selectedProjectTemplateId = null;
                state.selectedClientTemplateId = null;
              }

              state.notSaved = false;
              toast.success("Template saved");
            } else {
              toast.error("Template failed to save");
            }
          });

          builder.addCase(updateTemplate.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Template failed to save");
            } else {
              state.error = action.error;
              toast.error("Template failed to save");
            }
          })

          builder.addCase(deleteTemplate.fulfilled, (state, { payload, meta }) => {   
            if (payload?.success || payload.status == 200) {
              const templateId = payload.templateId;              
              const deletedIndex = state.allTemplates.findIndex((t: any) => t.id === templateId);
              state.allTemplates.splice(deletedIndex, 1);

              // switch (meta.arg.templateLevel) {
              //   case 0: 
              //     state.selectedBuiltInTemplateId = state.nextTemplateId;
              //     break;
              //   case 1: 
              //     state.selectedUserTemplateId = state.nextTemplateId;
              //     break;
              //   case 2: 
              //     state.selectedBoreholeTemplateId = state.nextTemplateId;
              //     break;
              //   case 3: 
              //     state.selectedProjectTemplateId = state.nextTemplateId;
              //     break;
              //   case 4: 
              //     state.selectedClientTemplateId = state.nextTemplateId;
              //     break;
              // }

              state.selectedBuiltInTemplateId = null;
              state.selectedUserTemplateId = null;
              state.selectedBoreholeTemplateId = null;              
              state.selectedProjectTemplateId = null;
              state.selectedClientTemplateId = null;

              state.selectedTemplateId = null;
              state.template = {...emptyTemplate}; //{};
              // if (state.userTemplates.length > 0) {
              //   const selectedTemplate = state.userTemplates[0];
              //   state.selectedBuiltInTemplateId = selectedTemplate.id;
              //   state.canSaveTemplate = !selectedTemplate?.cannotOverwrite;
              // }
              toast.success("Template deleted");
            } else {
              toast.error("Template failed to delete");
            }
          });

          builder.addCase(deleteTemplate.rejected, (state, action) => {
            if (action.payload) {
              state.error = action.payload;
              toast.error("Template failed to delete");
            } else {
              state.error = action.error;
              toast.error("Template failed to delete");
            }
          })

          builder.addCase(addTemplate.fulfilled, (state, { payload, meta }: any) => {
            //add to template list                      
            state.allTemplates.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true, templateLevel: meta.arg.templateLevel });
            switch (meta.arg.templateLevel) {
              case 0: 
              state.builtInTemplates.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true });
              break;
            case 1: 
              state.userTemplates.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true });
              break;
            case 2: 
              state.boreholeTemplates.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true });
              break;
            case 3: 
              state.projectTemplates.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true });
              break;
            case 4: 
              state.clientTemplates.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true });
              break;
            }
            
            if (!state.template) {
              state.template = {};
            }
            
            state.template.name = payload.name;            
            state.canSaveTemplate = true;            
            sortTemplates(state);
            if (state.nextTemplateId) 
            {   
                state.urlTemplateId = state.nextTemplateId;
                state.nextTemplateId = null;
            } else {
              state.urlTemplateId = payload.templateId;              
            }            
            state.notSaved = false;
            toast.success("Template saved");
          });

          builder.addCase(addTemplate.rejected, (state, { payload }) => {          
            toast.error("Template failed to save");
          });

          builder.addCase(getTrackData.pending, (state, { payload }) => {
            state.error = null;
            state.trackDataLoading  = true;
            state.minDepth = null;
            state.maxDepth = null;
          });

          builder.addCase(getTrackData.rejected, (state, { payload }) => {
            state.trackDataLoading  = false;
            state.error = payload;
            state.trackData = [];
          });

          builder.addCase(getTrackData.fulfilled, (state, action) => {
            state.trackDataLoading  = false;
            state.error = null;
            state.trackData = action.payload.trackData;
            state.trackImages = action.payload.trackImages;
            state.minDepth = action.payload.minimumDepth;
            state.maxDepth = action.payload.maximumDepth;
          });    
          
          // builder.addCase(getMultipleBoreholeTrackData.pending, (state, { payload }) => {
          //   state.error = null;
          //   state.trackDataLoading  = true;
          //   state.minDepth = null;
          //   state.maxDepth = null;
          // });

          // builder.addCase(getMultipleBoreholeTrackData.rejected, (state, { payload }) => {
          //   state.trackDataLoading  = false;
          //   state.error = payload;
          //   state.trackData = [];
          // });

          // builder.addCase(getMultipleBoreholeTrackData.fulfilled, (state, action) => {
          //   state.trackDataLoading  = false;
          //   state.error = null;
          //   state.trackData = action.payload.trackData;
          //   state.trackImages = action.payload.trackImages;
          //   state.minDepth = action.payload.minimumDepth;
          //   state.maxDepth = action.payload.maximumDepth;
          // });    
          
          builder.addCase(getTrackDataForTrackTypes.pending, (state, { payload }) => {
            state.error = null;
            state.trackDataLoading  = true;
            state.minDepth = null;
            state.maxDepth = null;
          });

          builder.addCase(getTrackDataForTrackTypes.rejected, (state, { payload }) => {
            state.trackDataLoading  = false;
            state.error = payload;
          });

          builder.addCase(getTrackDataForTrackTypes.fulfilled, (state, action) => {
            state.trackDataLoading = false;
            state.error = null;
            if (action.payload) 
            {
              if (action.payload.status >= 400) {
                debugger;
                state.error = action.payload.errors;
              } else {              
                action.payload.trackData.forEach((trackData: any) => state.trackData.push(trackData));
              }
            }
          });    

          builder.addCase(getMultipleBoreholeTrackDataForTrackTypes.pending, (state, { payload }) => {
            state.error = null;
            state.trackDataLoading  = true;
            state.minDepth = null;
            state.maxDepth = null;
          });

          builder.addCase(getMultipleBoreholeTrackDataForTrackTypes.rejected, (state, { payload }) => {
            state.trackDataLoading  = false;
            state.error = payload;
          });

          builder.addCase(getMultipleBoreholeTrackDataForTrackTypes.fulfilled, (state, action) => {
            state.trackDataLoading = false;
            state.error = null;
            if (action.payload) {              
              action.payload.trackData?.forEach((trackData: any) => state.groupTrackData.push(trackData));                          
              action.payload.trackImages?.forEach((trackImage: any) => state.groupTrackImages.push(trackImage));                    
              state.minDepth = action.payload.minimumDepth;
              state.maxDepth = action.payload.maximumDepth;                    
            }            
          });    
          
          builder.addCase(getMultipleBoreholeTrackDataForTemplate.pending, (state, { payload }) => {
            state.error = null;
            state.trackDataLoading  = true;
            state.minDepth = null;
            state.maxDepth = null;
          });

          builder.addCase(getMultipleBoreholeTrackDataForTemplate.rejected, (state, { payload }) => {
            state.trackDataLoading  = false;
            state.error = payload;
          });

          builder.addCase(getMultipleBoreholeTrackDataForTemplate.fulfilled, (state, action) => {
            state.trackDataLoading = false;
            state.error = null;            
            if (action.payload) {
              action.payload.trackData?.forEach((trackData: any) => state.groupTrackData.push(trackData));                          
              action.payload.trackImages?.forEach((trackImage: any) => state.groupTrackImages.push(trackImage));              
              state.minDepth = action.payload.minimumDepth;
              state.maxDepth = action.payload.maximumDepth;
            }            
          });    

          builder.addCase(getMultipleBoreholeTrackDataForView.pending, (state, { payload }) => {
            state.error = null;
            state.trackDataLoading  = true;
            state.minDepth = null;
            state.maxDepth = null;
          });

          builder.addCase(getMultipleBoreholeTrackDataForView.rejected, (state, { payload }) => {
            state.trackDataLoading  = false;
            state.error = payload;
          });

          builder.addCase(getMultipleBoreholeTrackDataForView.fulfilled, (state, action) => {
            state.trackDataLoading = false;
            state.error = null;            
            if (action.payload) {
              action.payload.trackData?.forEach((trackData: any) => state.groupTrackData.push(trackData));                          
              action.payload.trackImages?.forEach((trackImage: any) => state.groupTrackImages.push(trackImage));
              state.minDepth = action.payload.minimumDepth;
              state.maxDepth = action.payload.maximumDepth;
            }            
          });    

          builder.addCase(getTrackDataForImageTrackTypes.pending, (state, { payload }) => {
            state.trackDataLoading  = true;
          });

          builder.addCase(getTrackDataForImageTrackTypes.rejected, (state, { payload }) => {
            state.trackDataLoading  = false;
            state.error = payload;
          });

          builder.addCase(getTrackDataForImageTrackTypes.fulfilled, (state, action) => {
            //state.trackDataLoading  = false;
            //state.error = null;
            
            //state.imageMetaData = {...state.imageMetaData, ...action.payload.deepZoomMetaData };
            //action.payload.deepZoomMetaData.forEach((trackData: any) => state.trackData.push(trackData));            
            
            return {
              ...state,
              trackDataLoading: false,
              error: null,
              deepZoomMetaData: {...state.deepZoomMetaData, ...action.payload.deepZoomMetaData, boreholeName: action.payload.boreholeName }};
          });    
          
          builder.addCase(changeTemplateLevel.fulfilled, (state, action) => {
            const { meta } = action;             
            const templateId = meta.arg.id;
            let originalTemplate = state.allTemplates.find((t: any) => t.id === templateId);
            originalTemplate.templateLevel = meta.arg.templateLevel            
            state.selectedBuiltInTemplateId = null;
            state.selectedUserTemplateId = null;
            state.selectedBoreholeTemplateId = null;
            state.selectedProjectTemplateId = null;              
            state.selectedClientTemplateId = null;

            switch (meta.arg.templateLevel) {
              case 0: 
                state.selectedBuiltInTemplateId = templateId;
                break;
              case 1: 
                state.selectedUserTemplateId = templateId;
                break;
              case 2: 
                state.selectedBoreholeTemplateId = templateId;
                break;
              case 3: 
                state.selectedProjectTemplateId = templateId;
                break;
              case 4: 
                state.selectedClientTemplateId = templateId;
                break;
              }            
          });    

          builder.addCase(saveLithologyData.pending, (state, { payload }) => {
            state.trackDataLoading = true;
          });

          builder.addCase(saveLithologyData.rejected, (state, { payload }) => {
            state.trackDataLoading = false;
            state.error = payload;
          });

          builder.addCase(saveLithologyData.fulfilled, (state, action) => {
            state.trackDataLoading = false;            
            if (action.payload.success) {
              const trackTypeId = action.meta.arg.data.trackTypeId;
              const index = state.dataNotSaved.findIndex((t: any) => t == trackTypeId)
              if (index >= 0) {
                state.dataNotSaved.splice(index, 1);
              }

              toast.success("Data saved");            
            } else {
              toast.error("Error saving data");            
            }
          });   
          
          builder.addCase(saveTextData.pending, (state, { payload }) => {
            state.trackDataLoading = true;
          });

          builder.addCase(saveTextData.rejected, (state, { payload }) => {
            state.trackDataLoading = false;
            state.error = payload;
          });

          builder.addCase(saveTextData.fulfilled, (state, action) => {
            state.trackDataLoading = false;            
            if (action.payload.success) {
              const trackTypeId = action.meta.arg.data.trackTypeId;
              const index = state.dataNotSaved.findIndex((t: any) => t == trackTypeId)
              if (index >= 0) {
                state.dataNotSaved.splice(index, 1);
              }

              toast.success("Data saved");            
            } else {
              toast.error("Error saving data");            
            }
          });   
    }    
})

export const { addCurve,
               updateCurve,
               updateCurves,          
               setShowGridlines,
               setShowValueAxisAnnotation,
               setScaleType,
               setTrackWidth,
               setSelectedBuiltInTemplateId,
               setSelectedUserTemplateId,
               setSelectedBoreholeTemplateId,
               setSelectedProjectTemplateId,
               setSelectedClientTemplateId,   
               setSelectedTemplateId,            
               setNextTemplateId, 
               clearNotSaved,
               removeTrack,
               updateTrackOrder,
               addGraphTracks,
               addImageTracks,
               addCumulativeTrack,
               addTadpoleTrack,
               addLithologyTrack,
               addTextTrack,
               setDepthMajorIntervals,
               setShowDepthGridlines,
               setDepthMinorIntervals, 
               setShowDepthMinorIntervals,
               setTrackImageFilter,
               clearTemplatesLoaded,
               clearTemplateLoading,
               scrollTrackIntoView,
               scrollAdjustmentIntoView,
               setTrackCollapsed,
               setDepthUnit,
               clearTemplate,
               setViewer,
               changeDataValue,
               changeDepth,
               addDataValue,
               deleteDataValue,
               setTrackEditMode,
               addNewTemplate,
               clearTrackData,
               clearUrlTemplateId,
               addTextValue,
               changeTextData,
               addStatisticsTracks,
               deleteStatisticsTrack,
               addHorizontalBarGraphTracks } = templateSlice.actions

export default templateSlice.reducer;