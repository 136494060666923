import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { scaleLinear, scaleLog } from 'd3-scale';
import { curveMonotoneY, curveLinear, curveBasis, curveStepBefore, curveStepAfter, curveStep } from 'd3-shape';
import { extent } from 'd3-array';
import { select, area, axisTop, axisLeft, pointer, bisector, line, path, symbol, symbolTriangle, symbolDiamond, symbolSquare } from 'd3';
import FormatNumber from '../format-number';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import lodashDebounce from 'lodash.debounce';
import ReactModal from 'react-modal';
import { ResizeCallbackData  } from 'react-resizable';
import '../../../node_modules/react-resizable/css/styles.css';
import { v4 as uuidv4 } from 'uuid';
import { Step } from './step';
import TrackHeader from './track-header';
import { dashType } from './dash-type';

import './horizontal-bar-chart.scss';
import {
  useWindowWidth
} from '@react-hook/window-size'
import TrackWrapper from './track-wrapper';

export const getDefaultTicks = (width: number) => {
  if (width <= 50) {
    return 2;
  } else if (width <= 70) {
    return 3;
  } else if (width <= 100) {
    return 4;
  } else if (width <= 100) {
    return 5;
  } else if (width > 200) {
    return 8;
  } else {
    return 6;
  }
}

export default function HorizontalBarChart(props: any) {
  const svgRef: any = useRef();
  const widowWidthRef: any = useRef();
  const container: any = useRef();

  const { 
      chartData, 
      id, 
      track,
      curves,
      trackWidth,
      topMargin,
      startDepth, 
      endDepth,       
      showYAxis,
      showGridlines,
      showValueAxisAnnotation,
      scaleType,      
      setTrackWidth,
      editMode,      
      depthMajorIntervals,
      showDepthGridLines,
      deletedCurves,
      availableHeight,
      headerHeight,
      minHeaderHeight,
      headerBottomPadding,
      showDepthMinorIntervals,
      depthMinorIntervals,
      trackHeaderClick,
      updateCurve,      
      setMetaDataDepth     
    } = props;

  const { datas, scrollIntoView, isSelected } = chartData;
  

  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {      
    if (scrollIntoView && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: "smooth",
        block:"start",
        inline: "end"
      });
    }
  }, [scrollIntoView]);

  //const windowSize: any = useWindowDimensions();

  const windowWidth = useWindowWidth();

  useEffect(() => {
    widowWidthRef.current = windowWidth;
  },[windowWidth]);
 
  const [curveXRange, setCurveXRange] = useState<any>({});

  const onResizeDebounce = (e: SyntheticEvent, data: ResizeCallbackData) => {      
    setTrackWidth(id, data.size.width);
  };

  const onResize = useCallback(lodashDebounce(onResizeDebounce, 40), []);

  const margins = {
    top: topMargin,
    right: 5,
    bottom: 0,
    left: showYAxis ? 40 : 5,
  };

    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

    // const [width, setWidth] = useState<number>(trackWidth - margins.left - margins.right - 10);

    // useEffect(() => {
    //   setWidth(trackWidth - margins.left - margins.right - 10);
    // },[trackWidth, showYAxis]);
  const width = trackWidth - margins.left - margins.right - 10;    
  
  const trackHeight = availableHeight - headerBottomPadding;
  const svgHeight = trackHeight - minHeaderHeight - 1;
  const graphHeight = svgHeight - margins.top - margins.bottom;

  const getYRange = (data: any) => {
    if (startDepth >= 0 && endDepth && endDepth > startDepth) {
      return [startDepth, endDepth];
    }

    return extent(data, (d: any) => d.depth);
  };

  //const trackHeight = graphHeight + margins.top + margins.bottom + 2 + minHeaderHeight;
  
  

  const defaultTicks = getDefaultTicks(width);

  const xTicks = curves[0]?.manualMajorIntervals ? curves[0]?.majorIntervals : defaultTicks;
  const yTicks = depthMajorIntervals;

  var isMajorTick = function (index: any) {
    if (!showDepthMinorIntervals) {      
      return true;
    }

    return (index % (+depthMinorIntervals + 1)) === 0;
  }

  const addGridLines = (svg: any, xScale: any, yScale: any) => {    
    var xAxisGridlines = axisTop(xScale)    
    .tickFormat(f => "")    
    .tickSize(-graphHeight)
    //.tickSizeOuter(0)
    .ticks(xTicks);
 
    svg.select(".x-axis-grid")
    .call(xAxisGridlines);  

    let ticks = yTicks;

    if (showDepthMinorIntervals) {
      ticks += yTicks * (+depthMinorIntervals);
    }

    var yAxisGridlines = 
    axisLeft(yScale)
    .tickFormat(f => "")
    .tickSize(-width)
    .ticks(ticks);
 
    svg.select(".y-axis-grid")
    .selectAll("*")
    .remove();

    if (showDepthGridLines) {
      const yAxisGrid = svg.select(".y-axis-grid")
      .call(yAxisGridlines);

      yAxisGrid.selectAll("g")
        .filter(function (d : any, i : any) {
          return !isMajorTick(i);
        })
        .classed("grid-minor-tick", true);
    } 
  };

const onSetCurveXRange = (curveId: any, minimum: number, maximum: number, tickCount: number, logLessThaZero: boolean) => {
  minimum = Math.round(minimum * 100) / 100;
  maximum = Math.round(maximum * 100) / 100;
  setCurveXRange((prevState: any) => ({
    ...prevState,
    ...{ [curveId]: {minimum, maximum} }    
  }));  

  let curveIndex = curves.findIndex((curve: any) => curve.id === curveId);
  if (curveIndex < 0) {
    return;
  }

  let curve :any = curves[curveIndex];
  
  let newCurve =  { ...curve, xScaleMinimumExtent: minimum, xScaleMaximumExtent: maximum, logLessThaZero };

  if (!curve.manualScaleXMinimum || !curve.manualScaleXMaximum) {    
    if (!curve.manualScaleXMinimum) {
      newCurve.xScaleMinimum = minimum;
    }

    if (!curve.manualScaleXMaximum) {
      newCurve.xScaleMaximum = maximum;
    }    
  }

  if (!curves[curveIndex].manualMajorIntervals) {
    newCurve.majorIntervals = defaultTicks;
  } 
  
  updateCurve(id, newCurve);
};

const xScaleRange = (data: any, curve: any) => {
  if (curve.manualScaleXMinimum && curve.manualScaleXMaximum) {      
    return [curve.xScaleMinimum, curve.xScaleMaximum];
  }
  
  let dataWithinRange = [...data];
  if (startDepth) {
    dataWithinRange = dataWithinRange.filter((d: any) => d.depth >= startDepth);
  }

  if (endDepth) {
    dataWithinRange = dataWithinRange.filter((d: any) => d.depth <= endDepth);
  }
  
  const rangeExtent : any[] = extent(dataWithinRange, (d : any) => d.value);  
  let newCurve =  { ...curve, xScaleMinimumExtent: rangeExtent[0], xScaleMaximumExtent: rangeExtent[1] };
  if (curve.manualScaleXMinimum) {
    rangeExtent[0] = curve.xScaleMinimum;
  } 
  
  if (curve.manualScaleXMaximum) {
    rangeExtent[1] = curve.xScaleMaximum;
  } 
    
  if (!curve.manualScaleXMinimum || !curve.manualScaleXMaximum) {    
    if (!curve.manualScaleXMinimum) {
      newCurve.xScaleMinimum = rangeExtent[0];
    }

    if (!curve.manualScaleXMaximum) {
      newCurve.xScaleMaximum = rangeExtent[1];
    }    
  }

  //updateCurve(id, newCurve);
  //onSetDialogCurveProperties(curve.id, newCurve);

  //onSetCurveXRange(curve.id, rangeExtent[0], rangeExtent[1]);
  
  return rangeExtent;
};

const renderChart = (data: any, curve: any, showGrid: boolean, allCurves: any, allData: any) => {  
  const svg = select(svgRef.current!);
  
  let logLessThaZero = scaleType == 1 && data.some((d: any) => d.value <= 0);  
  if (logLessThaZero) {
    data = data.filter((d: any) => d.value > 0 || d.gap);
  }

  const yScale = scaleLinear()
  .domain(getYRange(data))
  .range([0, graphHeight]);
  
 const xScale = scaleType == 0 ? scaleLinear()
  .domain(xScaleRange(data, curve))  
  .range([0, width])  
  :
  scaleLog()
  .domain(xScaleRange(data, curve))
  .range([0, width]);

  if (!curve.manualScaleXMinimum && !curve.manualScaleXMaximum) {
    xScale.nice(xTicks);
  }

  onSetCurveXRange(curve.id, xScale.domain()[0], xScale.domain()[1], xScale.ticks(xTicks).length, logLessThaZero);

  if (showGrid) {    

    if (showValueAxisAnnotation) {
      const xAxis: any = axisTop(xScale)
      //.tickSizeOuter(0)    
      .tickFormat(f => FormatNumber(f))
      .ticks(xTicks);

      svg.select(".x-axis")
      .call(xAxis);
     
     svg.select(".x-axis").selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "start");
    } else {
      svg.select(".x-axis")
      .selectAll("*").remove();;
    }

    addGridLines(svg, xScale, yScale);
  }
 
 if (showYAxis) {       
     const yAxis : any = axisLeft(yScale).ticks(yTicks);
     svg.select(".y-axis").call(yAxis);
 }   

 
let lineData = data;
if (!curve.showLine) {
  lineData = [];
}

  let lineFillColor = 'none';
  if (curve.showFill) {    
    lineFillColor = curve.fillColor;
  }

  let lineColor = 'none';
  if (curve.showLine) {    
    lineColor = curve.lineColor;
  }

    //.attr('clip-path', `polygon(0px 0px, ${clipRight}px 0px, ${clipRight}px ${totalHeight}px, 0px ${totalHeight}px)`);

let gapFilteredData = data.filter((d: any) => !d.gap && xScale(d.value) < width && xScale(d.value) >= 0 && d.depth >= startDepth && d.depth <= endDepth);
let gaplessdData = data.filter((d: any) => !d.gap);

svg.selectAll(`#clip-points-${id}`).remove();
svg.append("defs")
.append("clipPath")
        .attr("id", `clip-points-${id}`)
      .append("rect")      
        .attr("width", width)
        .attr("height", graphHeight);
svg
    .selectAll(`.bar-${curve.id}`)
    .data(gaplessdData)
    .join('rect')
    .attr("class", `bar-${curve.id}`)
    .attr("x", 0)
    .attr("y", 
      function(d : any, i: number) {     
        return yScale(d.depth); 
        })
    .style('fill', lineFillColor)
    .attr('stroke', lineColor)
    .attr('stroke-width', curve.lineSize)  
    .attr('stroke-dasharray', dashType(curve))
    .attr("width", function(d: any) { return xScale(d.value); })
    .attr("height",
        function(datum: any, i: number) { 
        const nextDepth = i <= (gaplessdData.length - 2) ? gaplessdData[i + 1].depth : gaplessdData[i].depth;
        return yScale(nextDepth - gaplessdData[i].depth);
      })
     .attr('clip-path', 'url(#clip)');    ;


  addRolloverRect(svg, yScale, xScale, data, curve, allCurves, allData);
}

const addRolloverRect = (svg: any, yScale: any, xScale: any, data: any, curve: any, allCurves: any, allData: any) => {

  var bisect = bisector(function(d: any) { return d.depth; }).left;

  function handleMouseMove(event: any) {   
    const currentYPosition = pointer(event)[1];    
        
    const yValue = yScale.invert(currentYPosition);
    
    const index = bisect(data, yValue);
    
    const value = data[index]?.value
    const depth = data[index]?.depth

    if (value == null || !depth) {      
      return;
    }

    let values: any[]= [];
    allCurves.forEach((curve: any) => {
      var curveData = datas?.find((d: any) => curve.trackTypeId == d.trackTypeId);
      if (curveData && curveData.data && curveData?.data[index]?.depth === depth) {
        var val = curveData.data[index]?.value;
        values.push({ displayName: curve.displayName, value: val, units: curve.units, isAverage: curve.isAverage, scale: curve.scale })
      }
    });
    
    //setMetaDataDepth({ depth, values: [{ displayName: curve.displayName, value, units: curve.units}]});
    setMetaDataDepth({ depth, values });
    
    // Get the index of the xValue relative to the dataSet
    const focus = svg
    .select('.focus-circle');
        
    focus
    .attr("cx", xScale(value))
    .attr("cy", yScale(depth))
    .raise(); 
  }

  function handleMouseOver(event: any) {
  const focus = svg
  .select('.focus-circle');
  focus.style("opacity", 0.5);
  handleMouseMove(event);
  }

  function handleMouseOut() {
    const focus = svg
    .select('.focus-circle');    
    focus.style("opacity", 0);  
    setMetaDataDepth(null);
  }
  
  svg.append("rect")
  .attr("class", "overlay" + uuidv4())
  .attr("width", width)
  .attr("height", graphHeight)
  .style("opacity", 0)
  .on("mouseover", handleMouseOver)
  .on("mouseout", handleMouseOut)
  .on("mousemove", handleMouseMove);
};

  useEffect(() => {
    if (!datas || !curves) {
      return;
    }

    const reversedCurves = [...curves];
    reversedCurves.reverse();
    let count = 1;
    const totalCurves = reversedCurves.length;
    
    let allCurveData: any[] = [];
    reversedCurves.forEach((curve: any) => {
      var trackTrackData = datas?.find((d: any) => curve.trackTypeId == d.trackTypeId);      
      if (trackTrackData && trackTrackData.data) {
        allCurveData = [...allCurveData, trackTrackData.data];
      }      
    });   
    
    reversedCurves.forEach((curve: any) => {      
      var trackTrackData = datas?.find((d: any) => curve.trackTypeId == d.trackTypeId);      
      if (trackTrackData && trackTrackData.data) {
        renderChart(trackTrackData.data, curve, count == totalCurves, reversedCurves, datas);          
      }
      count++;
    });    
    
    deletedCurves?.forEach((curve: any) => {      
        renderChart([], curve, false, null, null);  
    });          
    
  }, [JSON.stringify(datas), 
      JSON.stringify(curves),
      availableHeight, 
      startDepth, 
      endDepth,       
      scaleType,
      trackWidth,
      editMode,
      depthMajorIntervals,
      showDepthGridLines,
      minHeaderHeight,
      showDepthMinorIntervals,
      depthMinorIntervals,
      showValueAxisAnnotation]);

  if (!datas) {    
    debugger;
    return null;
  }

const headerPadding = () => {  
  const totalHeight = headerHeight * curves.length - headerBottomPadding;
  const padding = minHeaderHeight - totalHeight;
  if (padding > 0) {
    return <div style={{minHeight: padding}}></div>
  }
  
  return null;
};

const headerCurves = curves;

  return (
    <TrackWrapper trackWidth={trackWidth} 
      onResize={onResize}
      chartHeight={trackHeight}
      scrollIntoView={scrollIntoView}
      editMode={isSelected}
      minWidth={40}
      trackClick={trackHeaderClick}>
      <div className="has-text-centered track-label" ref={container}>
        {headerPadding()}
        {(headerCurves || []).map((curve: any, index: number) => (
          <TrackHeader 
            key={curve.id}
            curve={curve} 
            trackWidth={trackWidth} 
            curveXRange={curveXRange} 
            isSelected={chartData.isSelected && index === 0}
            last={index === headerCurves.length - 1}
          />          
        ))}        
      </div>         
      <svg width={trackWidth}
        height={svgHeight}>     
        <defs>
          <clipPath id="clip">
            <rect className="clip-rect" x={0} y={0} width={1000} height={graphHeight} />
          </clipPath>
           {/* <clipPath id="clip-points">
            <rect className="clip-rect" id="clip-points-rect" x={0} y={0} width={width} height={graphHeight} />
          </clipPath>  */}
        </defs>
        <g ref={svgRef} transform={`translate(${margins.left}, ${margins.top})`}>
          <g className="unit">
              <text className="unit-text"></text>
          </g>
          <g className="x-axis-grid gridLines vertical-grid-lines" strokeOpacity={showGridlines ? 1 : 0 }/>
          <g className="y-axis-grid gridLines horizontal-grid-lines" strokeOpacity={showDepthGridLines ? 1 : 0 }/>
          <g className="x-axis" />          
          <g className="y-axis" />
          <circle className="focus-circle" r={5} fill="none" stroke="black" opacity={0}/>          
        </g>
      </svg>
    </TrackWrapper>
  );  
}
