import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import { select, pointer, bisector  } from 'd3';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import lodashDebounce from 'lodash.debounce';
import { ResizeCallbackData  } from 'react-resizable';
import '../../../../node_modules/react-resizable/css/styles.css';
import { v4 as uuidv4 } from 'uuid';
import {
  useWindowWidth
} from '@react-hook/window-size'
import TrackWrapper from '../track-wrapper';
import { AnyARecord } from 'dns';

export default function TextTrack(props: any) {
  const rootSvgRef: any = useRef();    
  const svgRef: any = useRef();
  const widowWidthRef: any = useRef();
  const container: any = useRef();
  const tooltipRef: any = useRef();

  const { 
      track, 
      id,       
      trackWidth,
      topMargin,
      startDepth, 
      endDepth, 
      depthUnit,
      showYAxis,
      showGridlines,
      showValueAxisAnnotation,
      scaleType,      
      setTrackWidth,
      editMode,      
      depthMajorIntervals,
      showDepthGridLines,
      deletedCurves,
      availableHeight,
      headerHeight,
      minHeaderHeight,
      headerBottomPadding,
      showDepthMinorIntervals,
      depthMinorIntervals,
      trackHeaderClick,
      updateCurve,
      parentRef,
      setMetaDataDepth,
      selectLithology,
      addLithology,
      trackEditMode
    } = props;

  const { datas, scrollIntoView, isSelected } = track;
  
  const trackEditModeRef: any = useRef();

  useEffect(() => {
    trackEditModeRef.current = trackEditMode;
  },[trackEditMode]);

  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {      
    if (scrollIntoView && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: "smooth",
        block:"start",
        inline: "end"
      });
    }
  }, [scrollIntoView]);

  
useEffect(() => {
  if (!datas) {
    return;
  }
  
  renderChart();
  
}, [JSON.stringify(datas),     
    availableHeight, 
    startDepth, 
    endDepth,       
    scaleType,
    trackWidth,
    editMode,
    showDepthMinorIntervals,
    depthMajorIntervals,
    showDepthGridLines,
    minHeaderHeight,
    depthMinorIntervals,
    showValueAxisAnnotation]);


  //const windowSize: any = useWindowDimensions();

  const windowWidth = useWindowWidth();

  useEffect(() => {
    widowWidthRef.current = windowWidth;
  },[windowWidth]);
 
  const [curveXRange, setCurveXRange] = useState<any>({});

  const onResizeDebounce = (e: SyntheticEvent, data: ResizeCallbackData) => {      
    setTrackWidth(id, data.size.width);
  };

  const onResize = useCallback(lodashDebounce(onResizeDebounce, 40), []);

  const margins = {
    top: topMargin + 7,
    right: 0,
    bottom: 0,
    left: 0,
  };

  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  const width2 = trackWidth - margins.left - margins.right;
  const graphWidth = width2 - 10;
  
  const trackHeight = availableHeight - headerBottomPadding + 8;
  const svgHeight = trackHeight - minHeaderHeight -2 ;
  const graphHeight = svgHeight - margins.top - margins.bottom;

  const getYRange = (data: any) => {    
    if (startDepth >= 0 && endDepth && endDepth > startDepth) {
      return [startDepth, endDepth];
    }

    if (!data) {
      return [0,0];
    }

    return extent(data, (d: any) => d.depth);
  };

  //const trackHeight = graphHeight + margins.top + margins.bottom + 2 + minHeaderHeight;

  if (!datas) {
    return null;
  }


  const textData = datas[0]?.data;  
  const reversedLithologyData = Array.from((textData || []));
  reversedLithologyData?.sort((a: any, b:any) => b.depth - a.depth);  

// const codesAndColors = [...new Map(lithologyData.map((item: any) =>
//   [item["value"], { code: item.value, color: item.color }])).values()].filter((value: any) => value.code);

const headerPadding = () => {    
  const totalHeaderHeight = headerHeight;
  const padding = minHeaderHeight - totalHeaderHeight - 5;    
  if (padding > 0) {
    return <div style={{minHeight: padding}}></div>
  }
  
  return null;
};

const convertLineBreaksToBr = (text: string) =>{
  if (!text) {
    return null;
  }
  
  return text.replace(/(?:\r\n|\r|\n)/g, "<br/>");
}

const renderChart = () => {  
  const svg = select(svgRef.current!);  
  const yScale = scaleLinear()
  .domain(getYRange(textData))
  .range([0, graphHeight]);
    

  svg.selectAll("rect").remove();
  svg.selectAll("foreignObject").remove();
  svg.selectAll(".magnify-glass").remove();

  const handleMouseOverMagnifyGlass = (event: any) =>{
    
    var tooltip = select(tooltipRef.current);   
        
        tooltip.style("top", (event.pageY + 10 )+"px").style("left",(event.pageX + 10)+"px").style("opacity", 1)
        .style("display", "block")
        .html("Zoom in to see hidden descriptions.");
  };

  const handleMouseOutMagnifyGlass = (event: any) => {    
    var tooltip = select(tooltipRef.current);    
      tooltip.style("opacity", 0).style("display", "none"); 
  };

  if (textData) {
    let lastMagnifyY: any = null;
    textData.forEach((data: any, index: number) => {            
      if (data.value) {     
           
        const y = yScale(data.fromDepth);
        if (y < graphHeight) {
          const height = yScale(data.toDepth) - y;

          const textStart = y < 0 ? 0 : y;
          let textHeight = y < 0 ? height + y : height;
          if (textHeight + y > graphHeight) {
            textHeight = graphHeight - y;
          }

          svg.append('rect')
          .attr('x', 0)
          .attr('y', y)
          .attr('width', graphWidth)
          .attr('height', height)
          .attr('fill', 'transparent')
          .attr('stroke', 'black')
          .attr('clip-path', `url(#clip-${id})`);   

          if (height < 10) {     
            if (lastMagnifyY === null || y - lastMagnifyY > 12) {
              svg.append('svg:image')
              .attr("class", "magnify-glass")
              .attr('x', width2 - 15)
              .attr('y', y)
              .attr('width', 15)
              .attr('height', 15)
              .attr("xlink:href", "/images/icons/magnify-glass.svg")
              .attr('title', "Zoom in to see hidden descriptions")
              .on("mouseover", handleMouseOverMagnifyGlass)
              .on("mouseout", handleMouseOutMagnifyGlass);
              lastMagnifyY = y;
            }            
          }
          
          const fontHeight = 10;
          let lines = Math.floor((textHeight - 6) / (fontHeight * 1));
          if (lines < 0) {
            lines = 0;
          }
          
          //textHeight = 6 + (lines * fontHeight);

          svg.append("foreignObject")
          .attr("x", 0)
          .attr("y", textStart)
          .attr("height", textHeight)
          .attr("width", graphWidth)
          .append("xhtml:body")
          .style("font", fontHeight + "px 'Roboto'")
          .html("<div class='text-track-description' style='width: " + (graphWidth - 6) + "px;-webkit-line-clamp: " + lines + "'>" + convertLineBreaksToBr(data.value) + "</div>");
          //.html("<div class='text-track-description' style='max-height: " + (textHeight - 6) + "px;height: " + (textHeight - 6) + "px;width: " + (graphWidth - 6) + "px;-webkit-line-clamp: " + lines + ";line-clamp: " + lines + ";'>" + convertLineBreaksToBr(data.value) + "</div>");
          
          //.text(data.value)
          //.call(wrap, width, y, height);

          // if y < 0 then need to put up arrow

          // if y > graphHeight then need to put down arrow

        }
      }
    });    
  }

    var rightBisect = bisector(function(d: any) { return d.depth; }).right;    
    
    function handleMouseMove(event: any) {         
      if (!textData || textData.length === 0) {
        return;
      }
     
       const currentYPosition = pointer(event)[1];
       const yValue = yScale.invert(currentYPosition);
       let fromDepth = 0;
       let toDepth = 0;
       const index = rightBisect(textData, yValue) - 1;       
       const value = textData[index]?.value;
       if (trackEditModeRef.current) {
          const index = rightBisect(textData, yValue);
          const value = textData[index]?.value;
          fromDepth = textData[index]?.depth;
          
          if (value == null || fromDepth == null) {
            return;
          }
           
          let valueArray: any[] = [];
          valueArray.push({ displayName: "Description", value: value, units: "", isAverage: false, scale: 1});
            
          setMetaDataDepth({ depth: fromDepth, values: valueArray});

         

        } else {    
          fromDepth = textData[index]?.fromDepth;
          toDepth = textData[index]?.toDepth;
          let valueArray: any[] = [];
          valueArray.push({ displayName: "Description", value: value?.substring(0,100), units: "", isAverage: false, scale: 1});
            
          setMetaDataDepth({ depth: fromDepth, values: valueArray});
        }       

        var tooltip = select(tooltipRef.current);   
        // tooltip
        // .style("left", select(svgRef.current).attr("cx") + "px")     
        // .style("top", select(svgRef.current).attr("cy") + "px");
        //tooltip.style("top", (currentYPosition )+"px").style("left",(currentXPosition)+"px");
        // console.log("event.pageX", event.pageX);
        // console.log("event.pageY", event.pageY);
        tooltip.style("top", (event.pageY + 10 )+"px").style("left",(event.pageX + 10)+"px").style("opacity", 1);
        //tooltip.style("top", (event.offsetY + 10 )+"px").style("left",(event.offsetX + 10)+"px").style("opacity", 1);
        //tooltip.style("top", ( 10 )+"px").style("left",(10)+"px").style("opacity", 1);
        tooltip.html("Depth: " + fromDepth + "m - " + toDepth +"m<br/>" + convertLineBreaksToBr(value))


     }     

    const debounce = (fn: Function, ms = 100) => {
      let timeoutId: ReturnType<typeof setTimeout>;
      return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
      };
    };

    const handleMouseClick = (event:any) => {
      const currentYPosition = pointer(event)[1];     
      const yValue = yScale.invert(currentYPosition);     
      if (trackEditModeRef.current) {   
        const data: any = reversedLithologyData.find((data: any) => data.depth < yValue);                
        selectLithology(track, data?.id);
      } else {
        const depth = Math.round(yValue * 100) / 100;
        addLithology(track.lithologyTypeId, depth);
      }
    };
   
    function handleMouseOver(event: any) {    
      if (!textData || textData.length === 0) {
        return;
      }
          
      var tooltip = select(tooltipRef.current);    
      tooltip.style("opacity", 1);
      tooltip.style("display", "block");
      handleMouseMove(event);
    }

    function handleMouseOut() {    
      var tooltip = select(tooltipRef.current);    
      tooltip.style("opacity", 0);    
      tooltip.style("display", "none"); 
      setMetaDataDepth(null);
    }

    svg.append("rect")
    .attr("class", "overlay" + uuidv4())
    .attr("width", graphWidth)
    .attr("height", graphHeight)
    .style("opacity", 0)
    .on("mouseover", handleMouseOver)
    .on("mouseout", handleMouseOut)
    .on("mousemove", handleMouseMove)
    .on("click", handleMouseClick);
}

return (
  <>
  <div ref={tooltipRef} className="text-track-tooltip"></div>
    <TrackWrapper trackWidth={trackWidth} 
      onResize={onResize}
      minWidth={100}
      chartHeight={trackHeight}
      scrollIntoView={scrollIntoView}
      editMode={isSelected}
      trackClick={trackHeaderClick}>
        {/* <div style={{fontSize: "10px"}}>
        <div className="text-track-description" style={{maxHeight:"95px", height: "94.98348623853211px",width: "143px", WebkitLineClamp: 5}}>asdffdfsd lkasm dlkasm ;dlma slkdm laskm<br/><br/>break<br/>dlkasmd lkasm dl;kasmdlk;msa lkmlkmlsdkmfkljfgn fdkljgn kjldnfgkj ndfkj gdfkjn  jkndfgkjfdng kfdjng kfjnf njs jksdnfisnf inis lsdf ;slkdnf kl;sdnf klsdnf klsdnf ksldjn klsdjnf kljsdn fisdnf isodfbn iosduanfb idsonf isdn isdon fiosdn isdfnf isdn fisdn isdn fijfsdn fjiksdn fisdanf iusdofb niusd</div>
        </div> */}

      <div className="track-label" ref={container}>        
        <div className={`has-text-centered track-label ${isSelected ? "highlighted-track" : ""}`} style={{backgroundColor: track.color}}>
          <div className="">{track.displayName}</div>          
        </div>
        {headerPadding()}        
      </div>         
      {/* {datas[0]?.data.map((data: any) => (<div>{data.depth}</div>))}  */}
      <svg width={trackWidth}
        height={svgHeight}
        ref={rootSvgRef}>    
        
        <defs>
          <clipPath id={`clip-${id}`}>
            <rect className="clip-rect" x={0} y={0} width={trackWidth - 10} height={graphHeight} />
          </clipPath>
        </defs>
        <g ref={svgRef} transform={`translate(${margins.left}, ${margins.top})`}>
          <g className="unit">
              <text className="unit-text"></text>
          </g>
          <g className="x-axis-grid gridLines vertical-grid-lines" strokeOpacity={showGridlines ? 1 : 0 }/>
          <g className="y-axis-grid gridLines horizontal-grid-lines" strokeOpacity={showDepthGridLines ? 1 : 0 }/>
          <g className="x-axis" />          
          <g className="y-axis" />          
        </g>
      </svg>
    </TrackWrapper>
    </>
  );  
}
