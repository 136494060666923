import Panel from '../panel';
import Moment from 'react-moment';

const InfoPanel = (props: any) => {
    const {
      metaDataDepth,
      depthUnit,
      latitude,
      longitude,
      loggingStarted,
      loggingEnded,
      loggingTeam,
      comments,
      status,
      equipment,
      image
    } = props;    


    //const { depth, values } = metaDataDepth;

return(
  <Panel title="Info" icon="Atlas Info Icon">
    <div className="panel-section">
      <h4>Metadata</h4>    
      {latitude && <div>Latitude: {latitude}&deg;</div>}
      {longitude && <div>Longitude: {longitude}&deg;</div>}
      {loggingStarted && <div>Log Start Date: <Moment format="DD/MM/YY">{loggingStarted}</Moment></div>}
      {loggingEnded && <div>Log End Date: <Moment format="DD/MM/YY">{loggingEnded}</Moment></div>}      
      {loggingTeam && <div>Logging Team: {loggingTeam}</div>}
      {comments && <div>Comments: {comments}</div>}
      {equipment && <div>Equipment: {equipment}</div>}
      {status && <div>Status: {status}</div>}
      {image && <img src={image} /> }
    </div>
  </Panel>
)};

export default InfoPanel;